import * as t from "io-ts";
import { MomentDate, MomentDateTime } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const PaymentType = t.keyof({
    Serviceentgelt: null,
    Anzahlung: null,
    Zwischenzahlung: null,
    Restzahlung: null,
    Barzahlung: null,
    Erstattung: null,
    Umbuchung: null,
});

export const PaymentC = t.type({
    value: Dinero,
    dueDate: MomentDate,
    receivedAt: Nullable(MomentDateTime),
    dunned1At: Nullable(MomentDateTime),
    dunned2At: Nullable(MomentDateTime),
    type: PaymentType,
});

export const PaymentM = t.intersection([
    PaymentC,
    t.type({
        id: t.number,
        commissionValue: Dinero,
        commissionRate: t.string,
        bookingId: t.number,
        canBeMarkedPaid: t.boolean,
        canBeDunned: t.boolean,
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentC = t.TypeOf<typeof PaymentC>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentM = t.TypeOf<typeof PaymentM>;
