import * as t from "io-ts";

export const RequiresConfirmationReason = t.type({
    code: t.string,
    descriptionDe: t.string,
    descriptionEs: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RequiresConfirmationReason = t.TypeOf<typeof RequiresConfirmationReason>;
