import * as t from "io-ts";

import { ListInvoice } from "./Invoice";
import { Voucher } from "./Voucher";

export const ApiResultManagerAccounting = t.type({
    invoices: t.array(ListInvoice),
    vouchers: t.array(Voucher),
    years: t.array(t.number),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerAccounting = t.TypeOf<typeof ApiResultManagerAccounting>;
