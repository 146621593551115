import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { MomentDate } from "./Moment";

export const CalendarDayStates = ["none", "closed", "blocked", "booked", "reserved", "request", "free"] as const;

export const CalendarDayState = t.keyof({
    none: null,
    closed: null,
    blocked: null,
    booked: null,
    reserved: null,
    request: null,
    free: null,
});

export const CalendarDay = t.type({
    arrivalPossible: t.boolean,
    date: MomentDate,
    state1: CalendarDayState,
    state2: CalendarDayState,
    tooltip: Nullable(t.string),
});

export const CalendarRow = t.type({
    apartmentPublicId: t.number,
    days: t.array(CalendarDay),
});

export const AggregateCalendarRow = t.type({
    apartmentPublicId: t.null,
    days: t.array(CalendarDay),
});

export const Calendar = t.type({
    startDate: MomentDate,
    endDate: MomentDate,
    rows: t.array(CalendarRow),
    aggregateRow: AggregateCalendarRow,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CalendarDayState = t.TypeOf<typeof CalendarDayState>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CalendarDay = t.TypeOf<typeof CalendarDay>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CalendarRow = t.TypeOf<typeof CalendarRow>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AggregateCalendarRow = t.TypeOf<typeof AggregateCalendarRow>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Calendar = t.TypeOf<typeof Calendar>;
