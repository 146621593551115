import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { Dinero } from "./Dinero";

export const SpecialCondition = t.type({
    id: t.number,
    descriptions: t.array(t.string),
    attributes: t.array(t.string),
    heading: t.string,
    body: t.string,
    headingEs: t.string,
    bodyEs: t.string,
    value: Nullable(Dinero),
    notCombinable: t.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SpecialCondition = t.TypeOf<typeof SpecialCondition>;
