import * as t from "io-ts";

import { MomentTimestamp } from "./Moment";
import { Nullable } from "./Nullable";

export const AirlineType = t.type({
    name: t.string,
    icao: t.string,
    iata: t.string,
});

export const AirportType = t.type({
    name: t.string,
});

export const Flight = t.union([
    t.type({
        actual_ident: t.string,
        aircrafttype: t.string,
        airline: Nullable(AirlineType),
        airport: AirportType,
        destination: t.string,
        ident: t.string,
        origin: t.string,
        arrivaltime: MomentTimestamp,
        departuretime: MomentTimestamp,
        meal_service: t.string,
        seats_cabin_business: t.number,
        seats_cabin_coach: t.number,
        seats_cabin_first: t.number,
        api: t.keyof({
            flightxml: null,
        }),
    }),
    t.type({
        ident: t.string,
        api: t.keyof({
            none: null,
        }),
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Flight = t.TypeOf<typeof Flight>;
