import * as t from "io-ts";

import { Salutation } from "./Salutation";

export const ApiResultManagerUpdateCustomerData = t.type({
    customerId: t.number,
    firstName: t.string,
    lastName: t.string,
    salutation: Salutation,
    email: t.string,
    telephoneNumber: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerUpdateCustomerData = t.TypeOf<typeof ApiResultManagerUpdateCustomerData>;
