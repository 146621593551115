import * as t from "io-ts";

export const ApiResultManagerCustomerNameEntry = t.type({
    id: t.number,
    name: t.string,
});

export const ApiResultManagerCustomerNames = t.type({
    customers: t.array(ApiResultManagerCustomerNameEntry),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCustomerNameEntry = t.TypeOf<typeof ApiResultManagerCustomerNameEntry>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCustomerNames = t.TypeOf<typeof ApiResultManagerCustomerNames>;
