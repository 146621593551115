import * as t from "io-ts";

import { Nullable, Optional } from "./Nullable";
import { Dinero } from "./Dinero";
import { Company } from "./Company";
import { ManagerM } from "./Manager";
import { PaymentM } from "./Payment";
import { MomentDate, MomentDateTime } from "./Moment";

const InvoiceType = t.keyof({
    Provisionsabrechnung: null,
    Abschlagszahlung: null,
    Sonstiges: null,
    Zahlungsabrechnung: null,
});

export const InvoiceBooking = t.type({
    id: t.number,
    apartmentName: t.string,
    commissionRate: t.string,
    commissionValue: Dinero,
    price: Dinero,
    start: MomentDate,
    customerName: t.string,
});

export const ListInvoice = t.type({
    id: t.string,
    year: t.number,
    sequentialIdYear: t.number,
    type: InvoiceType,
    createdAt: MomentDateTime,
    paidAt: Nullable(MomentDateTime),
    dunning1Date: Nullable(MomentDate),
    dunning2Date: Nullable(MomentDate),

    total: Dinero,
    totalGross: Dinero,
    vatAmount: Dinero,
    vatRate: Dinero,
    isReverseCharge: t.boolean,
    filename: t.string,

    company: Company,
    manager: ManagerM,

    payments: Optional(
        t.array(
            t.intersection([
                PaymentM,
                t.type({
                    apartmentName: t.string,
                    customerName: t.string,
                }),
            ]),
        ),
    ),
});

export const InvoiceSonstiges = t.intersection([
    ListInvoice,
    t.type({
        type: t.literal("Sonstiges"),
    }),
]);

export const InvoiceAbschlagszahlung = t.intersection([
    ListInvoice,
    t.type({
        type: t.literal("Abschlagszahlung"),
    }),
]);

export const InvoiceProvisionsabrechnung = t.intersection([
    ListInvoice,
    t.type({
        type: t.literal("Provisionsabrechnung"),
        invoices: t.array(InvoiceAbschlagszahlung),
        bookings: t.array(InvoiceBooking),
    }),
]);

export const InvoiceZahlungsabrechnung = t.intersection([
    ListInvoice,
    t.type({
        type: t.literal("Zahlungsabrechnung"),
    }),
]);

export const Invoice = t.union([
    InvoiceAbschlagszahlung,
    InvoiceProvisionsabrechnung,
    InvoiceZahlungsabrechnung,
    InvoiceSonstiges,
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Invoice = t.TypeOf<typeof Invoice>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceBooking = t.TypeOf<typeof InvoiceBooking>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceType = t.TypeOf<typeof InvoiceType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ListInvoice = t.TypeOf<typeof ListInvoice>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceAbschlagszahlung = t.TypeOf<typeof InvoiceAbschlagszahlung>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceProvisionsabrechnung = t.TypeOf<typeof InvoiceProvisionsabrechnung>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceZahlungsabrechnung = t.TypeOf<typeof InvoiceZahlungsabrechnung>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceSonstiges = t.TypeOf<typeof InvoiceSonstiges>;
