import * as t from "io-ts";

export const WishlistFincaData = t.type({
    imageUrl: t.string,
    name: t.string,
    url: t.string,
    price: t.string,
});

export const WishListItems = t.type({
    items: t.array(WishlistFincaData),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WishlistFincaData = t.TypeOf<typeof WishlistFincaData>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WishListItems = t.TypeOf<typeof WishListItems>;
