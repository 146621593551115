import * as t from "io-ts";

export const Salutation = t.keyof({
    familie: null,
    frau: null,
    herr: null,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Salutation = t.TypeOf<typeof Salutation>;
