import axios, { CancelTokenSource, AxiosRequestConfig } from "axios";
import * as t from "io-ts";

import { ApiResponse } from "jslib/types";
import makeApiResponse from "jslib/utils/makeApiResponse";

import translateApiError from "./translateApiError";

const cancellationTokenMap: Record<string, CancelTokenSource> = {};

async function makeApi<T extends t.Any>(
    url: string,
    responseType?: T,
    data?: any,
    options?: { cancel?: boolean },
): Promise<ApiResponse<t.TypeOf<T>>> {
    let config: AxiosRequestConfig = {};

    // Falls der Nutzer wünscht, dass zu dieser URL immer nur ein Request
    // gleichzeitig laufen kann
    if (options?.cancel) {
        if (cancellationTokenMap[url]) {
            // Es existiert schon ein laufender Request auf dieser URL
            cancellationTokenMap[url].cancel();
        }
        // Wir legen in jedem Fall einen neuen einen an
        cancellationTokenMap[url] = axios.CancelToken.source();
        config.cancelToken = cancellationTokenMap[url].token;
    }

    const loadData = async () => {
        const response = await axios.post(url, data, config);

        if (options?.cancel && response.config.cancelToken === cancellationTokenMap[url].token) {
            delete cancellationTokenMap[url];
        }

        return response.data;
    };

    const identity = (i: any) => t.success(i);
    const decode = responseType ? responseType.decode : identity;

    return translateApiError(makeApiResponse(async () => decode(await loadData())));
}

export default makeApi;
