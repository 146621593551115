import * as t from "io-ts";

export const AdditionalBed = t.type({
    adults: t.number,
    children: t.number,
    babies: t.number,
    name: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AdditionalBed = t.TypeOf<typeof AdditionalBed>;
