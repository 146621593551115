import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { ManagerM } from "./Manager";

export const ApiResultManagerLoginErrorType = t.keyof({
    "too-many": null,
    "wrong-credentials": null,
    "not-attempted": null,
});

export const ApiResultManagerLoginSuccess = t.type({
    manager: ManagerM,
    realManagerId: Nullable(t.number),
    numUnreadMessages: t.number,
    numUnconfirmedBookings: t.number,
    numUnpaidPayments: t.number,
    numUnpaidInvoices: t.number,
    numUnsetDataProperties: t.number,
    numFincaUnsetProperties: t.number,
});

export const ApiResultManagerLoginFailed = t.type({
    errorType: ApiResultManagerLoginErrorType,
    timeLeft: t.number,
    triesLeft: t.number,
});

export const ApiResultManagerLogin = t.union([ApiResultManagerLoginSuccess, ApiResultManagerLoginFailed]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerLoginErrorType = t.TypeOf<typeof ApiResultManagerLoginErrorType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerLoginSuccess = t.TypeOf<typeof ApiResultManagerLoginSuccess>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerLoginFailed = t.TypeOf<typeof ApiResultManagerLoginFailed>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerLogin = t.TypeOf<typeof ApiResultManagerLogin>;
