import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { MomentDate } from "./Moment";
import { Country } from "./Country";

export const CustomerC = t.type({
    displayName: t.string,
    email: t.string,
    hasLogin: t.boolean,
    id: t.number,
    isVerified: t.boolean,
    showGuestbookBadge: t.boolean,
});

export const ListCustomerM = t.type({
    birthdate: Nullable(MomentDate),
    displayName: t.string,
    email2: Nullable(t.string),
    email: Nullable(t.string),
    firstName: Nullable(t.string),
    hasLogin: Nullable(t.boolean),
    id: t.number,
    isNew: Nullable(t.boolean),
    isVip: Nullable(t.boolean),
    lastName: Nullable(t.string),
    salutation: Nullable(t.string),
    showGoogleLogin: Nullable(t.boolean),
    hasMultipleInquiries: Nullable(t.boolean),
});

export const CustomerM = t.intersection([
    ListCustomerM,
    t.type({
        addressAppendix: Nullable(t.string),
        bankIban: Nullable(t.string),
        bankOwner: Nullable(t.string),
        city: Nullable(t.string),
        country: Nullable(Country),
        managerCanEdit: Nullable(t.boolean),
        mobile2: Nullable(t.string),
        mobile: Nullable(t.string),
        phoneBusiness: Nullable(t.string),
        phoneOther: Nullable(t.string),
        phonePrivate: Nullable(t.string),
        postalCode: Nullable(t.string),
        street: Nullable(t.string),
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CustomerC = t.TypeOf<typeof CustomerC>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ListCustomerM = t.TypeOf<typeof ListCustomerM>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CustomerM = t.TypeOf<typeof CustomerM>;
