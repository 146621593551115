import * as t from "io-ts";

import { AdditionalBed } from "./AdditionalBed";
import { AdditionalBedPrice } from "./AdditionalBedPrice";
import { MomentDate } from "./Moment";

export const HasAdditionalBed = t.type({
    amount: t.number,
    start: MomentDate,
    end: MomentDate,
    additionalBed: AdditionalBed,
    additionalBedPrices: t.array(AdditionalBedPrice),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HasAdditionalBed = t.TypeOf<typeof HasAdditionalBed>;
