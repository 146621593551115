import * as t from "io-ts";

import { MomentDateTime } from "./Moment";
import { Nullable } from "./Nullable";

export const IcalSync = t.type({
    id: t.number,
    domain: Nullable(t.string),
    apartmentId: t.number,
    apartmentName: t.string,
    createdAt: MomentDateTime,
    lastImport: Nullable(MomentDateTime),
    lastExport: Nullable(MomentDateTime),
    exportToken: t.string,
    importUrl: Nullable(t.string),
    exportUrl: t.string,
    hasWarning: t.boolean,
    fincaId: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IcalSync = t.TypeOf<typeof IcalSync>;
