import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Dinero } from "./Dinero";

export const EcotasaDetails = t.type({
    basic_price: Dinero,
    price: Dinero,
    price_no_vat: Dinero,
    date: MomentDate,
    number_of_participants: t.number,
});

export const Ecotasa = t.type({
    total_no_vat: Dinero,
    total: Dinero,
    vat: Dinero,
    details: t.array(EcotasaDetails),
    has_9th_day_rule: t.boolean,
    has_off_season: t.boolean,
    has_children: t.boolean,
    included: t.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EcotasaDetails = t.TypeOf<typeof EcotasaDetails>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Ecotasa = t.TypeOf<typeof Ecotasa>;
