import * as t from "io-ts";

export const SearchFeatureType = t.keyof({
    aggregates: null,
    specials: null,
    features: null,
});

export const SearchFeature = t.type({
    name: t.string,
    slug: t.string,
});

export const SearchFeatureResults = t.record(SearchFeatureType, t.array(SearchFeature));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchFeatureType = t.TypeOf<typeof SearchFeatureType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchFeature = t.TypeOf<typeof SearchFeature>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchFeatureResults = t.TypeOf<typeof SearchFeatureResults>;
