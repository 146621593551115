import * as t from "io-ts";
import { OptionalNullable } from "./Nullable";

export const Rating = t.type({
    serviceKindnessCarrier: t.number,
    cleannessInside: t.number,
    cleannessOutside: t.number,
    serviceBroker: OptionalNullable(t.number),
    priceQuality: t.number,
    furniture: t.number,
    average: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Rating = t.TypeOf<typeof Rating>;
