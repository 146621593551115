import * as t from "io-ts";

import { Nullable } from "./Nullable";

export const NewFeature = t.type({
    featureId: t.number,
    name: t.string,
    nameEs: t.string,
    descriptionSuggestions: t.array(t.tuple([t.string, t.string])),
});

export const Feature = t.type({
    featureId: t.number,
    name: t.string,
    nameEs: t.string,
    canDelete: t.boolean,
    description: Nullable(t.string),
    descriptionEs: Nullable(t.string),
});

export const ApartmentFeatures = t.type({
    apartmentId: t.number,
    apartmentName: t.string,
    features: t.array(Feature),
});

export const FincaFeatures = t.type({
    ofFinca: t.array(Feature),
    ofApartments: t.array(ApartmentFeatures),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Feature = t.TypeOf<typeof Feature>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FincaFeatures = t.TypeOf<typeof FincaFeatures>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApartmentFeatures = t.TypeOf<typeof ApartmentFeatures>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NewFeature = t.TypeOf<typeof NewFeature>;
