import React from "react";
import clsx from "clsx";

import { ImageTupleBreakpoints, Breakpoint } from "jslib/types";

const BREAKPOINTS: [Breakpoint, number][] = [
    ["xl", 1280],
    ["lg", 1024],
    ["md", 768],
    ["sm", 415],
    ["xs", 0],
];

type PictureSrcsetProps = { image: ImageTupleBreakpoints; className?: string; alt: string; onClick?: () => void };

const PictureSrcset = React.forwardRef<HTMLImageElement, PictureSrcsetProps>(
    ({ alt, className, image, onClick }, ref) => {
        return (
            <picture className="d-block">
                {BREAKPOINTS.map(([bp, minWidth]) => (
                    <source
                        key={bp}
                        media={`(min-width: ${minWidth}px)`}
                        srcSet={`${image[bp][0][0]} 1x, ${image[bp][1][0]} 2x`}
                    />
                ))}

                <img
                    ref={ref}
                    onClick={onClick}
                    alt={alt}
                    className={clsx("w-100 h-auto", className)}
                    width={image["xs"][0][1]}
                    height={image["xs"][0][2]}
                    srcSet={`${image["xs"][0][0]} 1x, ${image["xs"][1][0]} 2x`}
                    src={image["xs"][0][0]}
                />
            </picture>
        );
    },
);

export default PictureSrcset;
