import * as t from "io-ts";

import { ListBookingM } from "./ListBooking";
import { IcalEvent } from "./IcalEvent";
import { IcalSync } from "./IcalSync";
import { ListCustomerM } from "./Customer";

export const ApiResultManagerCalendarInfoBooking = t.type({
    type: t.literal("booking"),
    booking: ListBookingM,
    customer: ListCustomerM,
    apartmentName: t.string,
});

export const ApiResultManagerCalendarInfoIcalEvent = t.type({
    type: t.literal("icalEvent"),
    icalEvent: IcalEvent,
    icalSync: IcalSync,
});

export const ApiResultManagerCalendarInfoEvent = t.union([
    ApiResultManagerCalendarInfoBooking,
    ApiResultManagerCalendarInfoIcalEvent,
]);

export const ApiResultManagerCalendarInfo = t.type({
    events: t.array(ApiResultManagerCalendarInfoEvent),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCalendarInfoBooking = t.TypeOf<typeof ApiResultManagerCalendarInfoBooking>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCalendarInfoIcalEvent = t.TypeOf<typeof ApiResultManagerCalendarInfoIcalEvent>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCalendarInfoEvent = t.TypeOf<typeof ApiResultManagerCalendarInfoEvent>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCalendarInfo = t.TypeOf<typeof ApiResultManagerCalendarInfo>;
