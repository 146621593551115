import getQueryParameter from "./getQueryParameter";

export default function setQueryParameter(uri: string, key: string, value: string | null): void {
    const re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    let newUri;

    const current = getQueryParameter(key);
    if (current === value) {
        return;
    }

    if (value === null) {
        if (uri.match(re)) {
            newUri = uri
                .replace(re, "$1$2")
                .replace(/[?&]$/, "")
                .replaceAll(/([?&])&+/g, "$1")
                .replace(/[?&]#/, "#");
        }
    } else {
        if (uri.match(re)) {
            newUri = uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
            let hash = "";
            if (uri.indexOf("#") !== -1) {
                hash = uri.replace(/.*#/, "#");
                uri = uri.replace(/#.*/, "");
            }
            const separator = uri.indexOf("?") !== -1 ? "&" : "?";
            newUri = uri + separator + key + "=" + value + hash;
        }
    }

    window.history.replaceState({}, "", newUri);
}
