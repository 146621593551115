import makeApi from "../utils/makeApi";

import { SearchResults, SearchFilters, SearchFeatureResults } from "types";
import { RentalCarSearchResponse, MomentDateTime } from "jslib/types";

const SearchApi = {
    search: (filters: SearchFilters) =>
        makeApi("/api/search", SearchResults, SearchFilters.encode(filters), { cancel: true }),

    getSearchFeatures: () => makeApi("/api/search/features", SearchFeatureResults),

    suggestRentalCars: (
        departure: MomentDateTime,
        arrival: MomentDateTime,
        numAdults: number,
        numChildren: number,
        numBabies: number,
        showElectric: boolean,
    ) =>
        makeApi("/api/sunnycars/suggestions", RentalCarSearchResponse, {
            departure,
            arrival,
            numAdults,
            numChildren,
            numBabies,
            showElectric,
        }),

    onDisplay: (sid: string, fincaPublicIds: number[]) =>
        makeApi("/api/search/display", undefined, { sid, fincaPublicIds }),

    onClick: (sid: string, destination: string) => makeApi("/api/search/click", undefined, { sid, destination }),
};

export default SearchApi;
