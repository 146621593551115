import * as t from "io-ts";

import { Finca } from "./Finca";
import { ListManagerM } from "./Manager";
import { IcalSync } from "./IcalSync";
import { Rating } from "./Rating";
import { Nullable } from "./Nullable";
import { NewFeature } from "./FincaFeatures";

export const ApiResultManagerFincaAccessType = t.keyof({
    bookings: null,
    invoices: null,
});

export const ApiResultManagerFincaHasManager = t.type({
    accessTo: t.array(ApiResultManagerFincaAccessType),
    manager: ListManagerM,
});

export const ApiResultManagerFinca = t.type({
    finca: Finca,
    hasCorona28: t.boolean,
    allowCancellationChanges: t.boolean,
    ratings: t.type({
        total: Nullable(Rating),
        byYear: t.record(t.string, Rating),
    }),
    icalSyncs: t.array(IcalSync),
    primaryManager: Nullable(ListManagerM),
    billingManager: Nullable(ListManagerM),
    managers: t.array(ApiResultManagerFincaHasManager),
    availableFeatures: t.array(NewFeature),
    newFeatures: t.array(NewFeature),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerFincaAccessType = t.TypeOf<typeof ApiResultManagerFincaAccessType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerFincaHasManager = t.TypeOf<typeof ApiResultManagerFincaHasManager>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerFinca = t.TypeOf<typeof ApiResultManagerFinca>;
