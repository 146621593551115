import * as t from "io-ts";

export const ApiResultManagerListEntry = t.type({
    displayName: t.string,
    id: t.number,
    email: t.string,
});

export const ApiResultManagerList = t.type({
    managers: t.array(ApiResultManagerListEntry),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerListEntry = t.TypeOf<typeof ApiResultManagerListEntry>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerList = t.TypeOf<typeof ApiResultManagerList>;
