import * as t from "io-ts";

import { MomentDateTime } from "./Moment";
import { Nullable } from "./Nullable";
import { User } from "./User";

export const GuestbookEntry = t.type({
    name: Nullable(t.string),
    date: t.string,
    createdAt: MomentDateTime,
    text: Nullable(t.string),
    textTranslationAutomatic: Nullable(t.string),
    managerRead: t.boolean,
    isActive: t.boolean,
    bookingId: t.number,
    comment: Nullable(t.string),
    commentDate: Nullable(MomentDateTime),
    commenterId: Nullable(t.number),
    commenter: Nullable(User),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GuestbookEntry = t.TypeOf<typeof GuestbookEntry>;
