import Select from "react-select";

// Gibt eine Kopie des Sets zurück, mit dem Wert v entfernt, falls er existiert
// oder mit dem Wert v hinzugefügt, falls er fehlt.
const toggleSetValue = <T extends any>(s: Set<T>, v: T): Set<T> => {
    const n = new Set(s);
    if (n.has(v)) {
        n.delete(v);
    } else {
        n.add(v);
    }
    return n;
};

type SetFilterProps<T, U> = {
    list: T[];
    value: null | Set<U>;
    onChange: (newSet: Set<U>) => void;
    keyGetter: (obj: T) => U;
    labelGetter: (obj: T) => string;
    countGetter?: (obj: T) => number | null | undefined;
    countColor?: "secondary" | "primary" | "success";
    additionalListHeadline?: string;
    additionalList?: T[];
};

// Zeige eine Liste an Checkboxen an. list enhält eine Liste von T-Objekten.
// keyGetter und labelGetter werden mit T-Objekten aufgerufen und geben Keys für
// das Set bzw labels zur Anzeige zurück. onChange wird bei Änderung aufgerufen
// und erhält ein neues Set mit den Keys der selektierten Checkboxen. value ist
// das aktuelle Set der selektierten Checkboxen.
const SetFilter = <T, U>({
    list,
    value,
    onChange,
    keyGetter,
    labelGetter,
    countGetter,
    countColor,
    additionalListHeadline,
    additionalList,
}: React.PropsWithChildren<SetFilterProps<T, U>>) => (
    <>
        <div className="form-group">
            {list.map((obj) => {
                const key = keyGetter(obj);
                const count = countGetter && countGetter(obj);
                return (
                    <div key={String(key)} className="option-label-span">
                        <div className="form-check">
                            <input
                                id={`sf_${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={!!(value && value.has(key))}
                                onChange={(e) => onChange(toggleSetValue(value || new Set([]), key))}
                            />
                            <label htmlFor={`sf_${key}`} className="form-check-label">
                                {labelGetter(obj)}
                            </label>
                            {Number.isInteger(count) && (
                                <div className="float-right option-count-span">
                                    <span
                                        id="obj_agritourism_count"
                                        className={`badge badge-pill badge-${countColor || "secondary"}`}
                                    >
                                        {count}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>

        {additionalListHeadline && <h6>{additionalListHeadline}</h6>}

        {additionalList && (
            <Select
                className="my-3"
                isMulti
                closeMenuOnSelect={false}
                noOptionsMessage={() => "Nichts gefunden"}
                placeholder="Suche..."
                options={additionalList
                    .filter((obj) => (countGetter && Number.isInteger(countGetter(obj)) ? countGetter(obj)! > 0 : true))
                    .map((obj) => ({
                        label:
                            labelGetter(obj) +
                            (countGetter && Number.isInteger(countGetter(obj)) ? ` (${countGetter(obj)})` : ""),
                        value: keyGetter(obj),
                    }))}
                value={additionalList
                    .filter((obj) => (value || new Set()).has(keyGetter(obj)))
                    .map((obj) => ({
                        label: labelGetter(obj),
                        value: keyGetter(obj),
                    }))}
                onChange={(options) =>
                    onChange(
                        new Set([
                            ...Array.from(value || []).filter((key) => list.find((obj) => keyGetter(obj) === key)),
                            ...options.map((option) => option.value),
                        ]),
                    )
                }
            />
        )}
    </>
);

export default SetFilter;
