import * as t from "io-ts";

export const BookingStatusName = t.keyof({
    Anfrage: null,
    Betreiberbuchung: null,
    Betreiberoptionierung: null,
    Festbuchung: null,
    Onlinebuchung: null,
    Reservierung: null,
    Storniert: null,
});

export const BookingStatus = t.type({
    id: t.number,
    name: BookingStatusName,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingStatusName = t.TypeOf<typeof BookingStatusName>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingStatus = t.TypeOf<typeof BookingStatus>;
