import i18n from "i18next";

import de from "./de";

const i18nInit = () => {
    i18n.init({
        resources: {
            de,
        },
        fallbackLng: "de",
        interpolation: {
            escapeValue: false,
        },
        debug: process.env.NODE_ENV === "development",
    });
};

export default i18nInit;
