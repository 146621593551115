import * as t from "io-ts";

import { MomentDateTime } from "./Moment";
import { RentalCarRate } from "./RentalCarRate";
import { VehicleCategoryCode, VehicleBuildCode, VehicleConfigurationKey, MeetingTypeKey } from "./RentalCarVehicle";

// io-ts types
export const RentalCarSearchParams = t.type({
    selectedVehicleCategories: t.array(VehicleCategoryCode),
    selectedVehicleBuilds: t.array(VehicleBuildCode),
    selectedConfigurations: t.array(VehicleConfigurationKey),
    selectedMeetingTypes: t.array(MeetingTypeKey),
    departure: MomentDateTime,
    arrival: MomentDateTime,
});

export const RentalCarSearchResponseSuccess = t.type({
    Rates: t.array(RentalCarRate),
});

export const RentalCarSearchResponseFailure = t.type({
    code: t.string,
    message: t.string,
});

export const RentalCarSearchResponse = t.union([RentalCarSearchResponseSuccess, RentalCarSearchResponseFailure]);

// Consts

export const VehicleCategories = [
    { Name: "Alle Fahrzeugkategorien", Code: "_ALL" },
    { Name: "Kleinstwagen", Code: "A" },
    { Name: "Kleinwagen", Code: "E" },
    { Name: "Untere Mittelklasse", Code: "C" },
    { Name: "Mittelklasse", Code: "I" },
    { Name: "Obere Mittelklasse", Code: "S" },
    { Name: "Familenwagen", Code: "F" },
];

export const VehicleBuilds = [
    { Name: "Alle Fahrzeugtypen", Code: "_ALL" },
    { Name: "Cabrio", Code: "1" },
    { Name: "Minivan", Code: "5" },
    { Name: "Limousine", Code: "7" },
    { Name: "Kombi", Code: "8" },
    { Name: "SUV", Code: "9" },
];

export const VehicleConfigurations = [
    { text: "Klimaanlage", key: "ac" },
    { text: "Automatisches Getriebe", key: "a" },
    { text: "Elektroauto", key: "ec" },
    { text: "2 Türen", key: "2dr" },
    { text: "4+ Türen", key: "4dr" },
];

export const MeetingTypes = [
    { text: "Alle Anmietstationen", key: "_ALL" },
    { text: "Shuttle-Service", key: "transfer" },
    { text: "Flughafenschalter", key: "airport" },
];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarSearchParams = t.TypeOf<typeof RentalCarSearchParams>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarSearchResponse = t.TypeOf<typeof RentalCarSearchResponse>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarSearchResponseSuccess = t.TypeOf<typeof RentalCarSearchResponseSuccess>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarSearchResponseFailure = t.TypeOf<typeof RentalCarSearchResponseFailure>;
