import * as t from "io-ts";

import { Finca } from "./Finca";

export const ApiResultManagerFincas = t.type({
    fincas: t.array(Finca),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerFincas = t.TypeOf<typeof ApiResultManagerFincas>;
