import "moment/locale/de";

const de = {
    translation: {
        errors: {
            serverError: "Es gab einen Server-Fehler beim Laden. Bitte versuchen Sie es später erneut.",
            networkError: "Die Seite konnte nicht geladen werden. Sind Sie mit dem Internet verbunden?",
            unknownError: "Es gab einen unbekannten Fehler beim Laden. Bitte versuchen Sie es später erneut.",
            notFoundError: "Die Seite wurde nicht gefunden.",
        },
    },
};

export default de;
