import React from "react";
import { usePopper } from "react-popper";

import range from "jslib/utils/range";
import { SearchResultFinca } from "types";

const RATING_ELEMENTS: { key: string; label: string }[] = [
    {
        key: "serviceKindnessCarrier",
        label: "Service & Freundlichkeit Vermieter",
    },
    {
        key: "cleannessInside",
        label: "Sauberkeit Innen",
    },
    {
        key: "cleannessOutside",
        label: "Sauberkeit Außen",
    },
    {
        key: "priceQuality",
        label: "Preis-/Leistungsverhältnis",
    },
    {
        key: "furniture",
        label: "Ausstattung",
    },
];

const FiveStarRating: React.FC<{ average: number }> = ({ average }) => {
    const numFullStars: number = Math.floor(average + 0.25);
    const showHalfStar: boolean = average - numFullStars >= 0.25;
    const numEmptyStars: number = 5 - numFullStars - (showHalfStar ? 1 : 0);

    return (
        <div className="star-rating-inline">
            {range(numFullStars).map((i) => (
                <i key={i} className="fa-solid fa-star" />
            ))}
            {showHalfStar && <i className="fa fa-star-half-stroke" />}
            {range(numEmptyStars).map((i) => (
                <i key={i} className="fa-regular fa-star" />
            ))}
        </div>
    );
};

const FincaRating: React.FC<{ finca: SearchResultFinca }> = ({ finca }) => {
    const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
    const [isPopperVisible, setIsPopperVisible] = React.useState<boolean>(false);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: "bottom",
    });

    // Wird immer ausgeführt, wenn auf das Dokument geklickt wird. Schließt das
    // Popper, wenn außerhalb des Poppers geklickt wird
    const handleDocumentClick = React.useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (referenceElement && referenceElement.contains(event.target as Node)) {
                return;
            }
            setIsPopperVisible(false);
        },
        [setIsPopperVisible, referenceElement],
    );

    // Installiere Klick-Handler
    React.useEffect(() => {
        if (isPopperVisible) {
            document.addEventListener("click", handleDocumentClick);
            document.addEventListener("touchend", handleDocumentClick);
            return () => {
                document.removeEventListener("click", handleDocumentClick);
                document.removeEventListener("touchend", handleDocumentClick);
            };
        }
    }, [handleDocumentClick, isPopperVisible]);

    return (
        <div className="finca-card__rating">
            {finca.isNew && finca.rating.count < 3 ? (
                <p className="lead mb-0">
                    <span className="badge badge-success">Neu im Angebot!</span>
                </p>
            ) : finca.rating.count >= 3 ? (
                <>
                    <small className="font-weight-bold pr-2">Kundenwertungen:</small>

                    <div
                        className="d-flex align-items-center"
                        ref={setReferenceElement}
                        onClick={() => {
                            update && update();
                            setIsPopperVisible(!isPopperVisible);
                        }}
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                    >
                        <FiveStarRating average={finca.rating.average} />
                        <a
                            href={finca.url + "/gaestebuch"}
                            onClick={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                        >
                            <i className="fa fa-fw fa-angle-down" />
                            {finca.rating.count}
                        </a>
                    </div>

                    <div
                        ref={setPopperElement}
                        {...attributes.popper}
                        className={isPopperVisible ? "popover p-2" : ""}
                        style={{ ...styles.popper, visibility: isPopperVisible ? "visible" : "hidden" }}
                    >
                        <div className="popover-body">
                            <div className="d-flex align-items-center">
                                <FiveStarRating average={finca.rating.average} />
                                <span className="ml-2 h6 my-0">
                                    {String(finca.rating.average.toFixed(1)).replace(".", ",")} von 5
                                </span>
                            </div>
                            <p className="text-muted mb-0">{finca.rating.count} Bewertungen</p>

                            {finca.guestbookPreview && (
                                <blockquote className="my-2 font-italic border-left pl-2">
                                    &bdquo;{finca.guestbookPreview}&rdquo;{" "}
                                    {finca.guestbookPreviewAuthor && (
                                        <cite className="small">({finca.guestbookPreviewAuthor.trim()})</cite>
                                    )}
                                </blockquote>
                            )}

                            {RATING_ELEMENTS.map((elem) => (
                                <React.Fragment key={elem.key}>
                                    {elem.label}:
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                                width: Math.round(((finca.rating as any)[elem.key] / 5) * 100) + "%",
                                            }}
                                        >
                                            {String((finca.rating as any)[elem.key].toFixed(1)).replace(".", ",")}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}

                            <hr />
                            <p className="mb-0">
                                <a href={finca.url + "/gaestebuch"}>
                                    Alle Bewertungen anzeigen <i className="fa fa-fw fa-angle-right" />
                                </a>
                            </p>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default FincaRating;
