import * as t from "io-ts";

import { News } from "./News";

export const ApiResultManagerHome = t.type({
    news: t.array(News),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerHome = t.TypeOf<typeof ApiResultManagerHome>;
