import React, { ReactNode } from "react";
import moment from "moment";

import { SearchFilters, SearchFeatureResults, REGION_GROUPS, RegionObject, RegionGroup } from "types";
import { DURATIONS, SEARCH_OBJECT_TYPES, SEA_FEATURES } from "./SearchSidebar";

const SearchFilterPills: React.FC<{ searchFilters: SearchFilters; allSearchFeatures?: SearchFeatureResults }> = ({
    searchFilters,
    allSearchFeatures,
}) => {
    const filterPills: ReactNode[] = React.useMemo(() => {
        const filterPills: ReactNode[] = [];

        // Freitext
        if (searchFilters.text) {
            filterPills.push(
                <>
                    <i className="fa fa-fw fa-magnifying-glass"></i>
                    {`"${searchFilters.text}"`}
                </>,
            );
        }

        // Zeitraum
        if (searchFilters.daterange) {
            filterPills.push(
                <>
                    <i className="fa fa-fw fa-calendar"></i>
                    {moment(searchFilters.daterange.start).format("L")}
                    {"\u2013"}
                    {moment(searchFilters.daterange.end).format("L")}
                </>,
            );
            if ("duration" in searchFilters.daterange && searchFilters.daterange["duration"]) {
                const duration = searchFilters.daterange.duration;
                const durationLabel =
                    DURATIONS.find((o) => o.duration[0] === duration[0] && o.duration[1] === duration[1])?.label ||
                    `${duration[0]}-${duration[1]} Tage`;
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-arrows-left-right"></i>
                        {durationLabel}
                    </>,
                );
            }
        }

        // Objekttyp
        if (searchFilters.objectType) {
            searchFilters.objectType.forEach((objectType) => {
                const label = SEARCH_OBJECT_TYPES.find((ot) => ot.key === objectType)?.label || objectType;
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-house"></i>
                        {label}
                    </>,
                );
            });
        }

        // Belegung
        if (searchFilters.participants) {
            if (searchFilters.participants.adults > 0) {
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-user"></i>
                        {searchFilters.participants.adults} Erwachsene{searchFilters.participants.adults === 1 && "r"}
                    </>,
                );
            }
            if (searchFilters.participants.children > 0) {
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-user"></i>
                        {searchFilters.participants.children} Kind{searchFilters.participants.children > 1 && "er"}
                    </>,
                );
            }
            if (searchFilters.participants.babies > 0) {
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-user"></i>
                        {searchFilters.participants.babies} Baby{searchFilters.participants.babies > 1 && "s"}
                    </>,
                );
            }
        }

        // Region
        if (searchFilters.region) {
            const label =
                REGION_GROUPS.reduce((acc: RegionObject[], cur: RegionGroup) => [...acc, ...cur.regions], []).find(
                    (r) => r.key === searchFilters.region,
                )?.label || searchFilters.region;
            filterPills.push(
                <>
                    <i className="fa-regular fa-fw fa-map"></i>
                    {label}
                </>,
            );
        }

        // Features
        if (searchFilters.features) {
            searchFilters.features.forEach((feature) => {
                const allAggregates = allSearchFeatures
                    ? [...SEA_FEATURES, ...allSearchFeatures.aggregates, ...allSearchFeatures.features]
                    : SEA_FEATURES;
                const label = allAggregates.find((f) => f.slug === feature)?.name || feature;
                filterPills.push(
                    <>
                        <i className="fa fa-fw fa-filter"></i>
                        {label}
                    </>,
                );
            });
        }

        return filterPills;
    }, [searchFilters, allSearchFeatures]);

    return filterPills.length > 0 ? (
        <p>
            {filterPills.map((content, idx) => (
                <span key={idx} className="d-inline-block mr-1 mb-1 py-1 badge-pill badge-light border">
                    {content}
                </span>
            ))}
        </p>
    ) : null;
};

export default SearchFilterPills;
