import React from "react";

import { SearchResults, SearchFilters } from "types";
import { MomentDate, RentalCarSearchResponse } from "jslib/types";
import Api from "api";

import SearchResult from "components/SearchResult";
import RentalCars from "components/RentalCars";

function getArrivalDeparture(result: SearchResults): [MomentDate, MomentDate] | null {
    const firstResultWithBooking = result.results.find((result) => result.bookings.length > 0);

    if (firstResultWithBooking) {
        return [firstResultWithBooking.bookings[0].start, firstResultWithBooking.bookings[0].end];
    } else if (result.searchQuery.daterange) {
        if ("duration" in result.searchQuery.daterange) {
            return [
                result.searchQuery.daterange.start,
                result.searchQuery.daterange.start.clone().add(result.searchQuery.daterange.duration[0], "days"),
            ];
        } else {
            return [result.searchQuery.daterange.start, result.searchQuery.daterange.end];
        }
    } else {
        return null;
    }
}

const ResultList: React.FC<{
    result: SearchResults;
    searchFilters: SearchFilters | null;
    onDisplay?: (fincaPublicId: number) => void;
    onClick?: (event: any) => Promise<unknown>;
}> = ({ result, searchFilters, onDisplay, onClick }) => {
    const nonExtendedResults = React.useMemo(() => result.results.filter((result) => !result.isExtended), [result]);
    const extendedResults = React.useMemo(() => result.results.filter((result) => result.isExtended), [result]);
    const hasAnyResult = nonExtendedResults.length + extendedResults.length > 0;
    const [suggestedRentalCars, setSuggestedRentalCars] = React.useState<RentalCarSearchResponse | null | undefined>(
        null,
    );

    const startAndEnd = getArrivalDeparture(result);
    const [start, end] = startAndEnd || [null, null];
    React.useEffect(
        () => {
            const loadRentalCarSuggestion = async (
                start: MomentDate,
                end: MomentDate,
                adults: number,
                children: number,
                babies: number,
                showElectric: boolean,
            ) => {
                setSuggestedRentalCars(undefined);
                const response = await Api.suggestRentalCars(start, end, adults, children, babies, showElectric);
                if (response.apiSuccess) {
                    setSuggestedRentalCars(response.data);
                } else {
                    setSuggestedRentalCars(null);
                }
            };

            const startAndEnd = getArrivalDeparture(result);
            if (startAndEnd && result.results.length > 0) {
                const [start, end] = startAndEnd;
                loadRentalCarSuggestion(
                    start,
                    end,
                    result.searchQuery.participants?.adults || 0,
                    result.searchQuery.participants?.children || 0,
                    result.searchQuery.participants?.babies || 0,
                    (result.searchQuery.features || []).includes("Lademöglichkeit E-Auto"),
                );
            }
        },
        // Wir wollen nur dann neue Mietwagen laden, wenn die Mitfahrer oder das
        // Datum sich ändern.
        /* eslint-disable react-hooks/exhaustive-deps */
        [
            String(start),
            String(end),
            result.searchQuery.participants?.adults,
            result.searchQuery.participants?.children,
            result.searchQuery.participants?.babies,
        ],
        /* eslint-enable react-hooks/exhaustive-deps */
    );

    return (
        <>
            <h2 className="h1 d-block d-md-none my-3">Ergebnisse</h2>

            {!hasAnyResult && (
                <div className="alert alert-info">
                    <h4 className="alert-heading">Leider keine Ergebnisse gefunden</h4>
                    <p>
                        Leider konnten wir für Ihre Suchanfrage keine Ergebnisse finden. Bitte versuchen Sie, Ihre Suche
                        allgemeiner zu gestalten.
                    </p>
                    <hr />
                    <p className="mb-0">Alternativ beraten wir Sie gern auch persönlich!</p>
                </div>
            )}

            {nonExtendedResults.slice(0, 3).map((result, idx) => (
                <SearchResult key={idx} result={result} onDisplay={onDisplay} onClick={onClick} />
            ))}
            {nonExtendedResults.length > 2 &&
                (suggestedRentalCars === undefined ? (
                    <div className="spinner" />
                ) : suggestedRentalCars && "Rates" in suggestedRentalCars && start && end ? (
                    <RentalCars rates={suggestedRentalCars.Rates} start={start} end={end} />
                ) : null)}
            {nonExtendedResults.slice(3).map((result, idx) => (
                <SearchResult key={idx} result={result} onDisplay={onDisplay} onClick={onClick} />
            ))}

            {extendedResults.length > 0 && (
                <>
                    <hr />
                    <h3 className="h4">Weitere Ergebnisse</h3>
                    {nonExtendedResults.length === 0 && (
                        <p>Leider konnten wir keine genau passenden Termine für Sie finden.</p>
                    )}
                    <p>Die folgenden Ergebnisse weichen nur leicht von Ihrem gewünschten Zeitraum ab:</p>
                    <hr />
                </>
            )}
            {extendedResults.map((result, idx) => (
                <SearchResult key={idx} result={result} onDisplay={onDisplay} onClick={onClick} />
            ))}
        </>
    );
};

export default ResultList;
