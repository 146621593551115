import * as t from "io-ts";

import { MomentDateTime } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const CancellationRule = t.type({
    until: MomentDateTime,
    untilReason: t.string,
    untilReasonEs: t.string,
    price: Nullable(Dinero),
    priceReason: t.string,
    priceReasonEs: t.string,
    valid: t.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CancellationRule = t.TypeOf<typeof CancellationRule>;
