import * as t from "io-ts";

import { MessageRecipient } from "./Message";

export const ApiResultManagerMessageRecipients = t.type({
    users: t.array(MessageRecipient),
    groups: t.array(MessageRecipient),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerMessageRecipients = t.TypeOf<typeof ApiResultManagerMessageRecipients>;
