import * as t from "io-ts";

import { Participant } from "./Participant";
import { PaymentM } from "./Payment";

export const ApiResultManagerPaymentListEntry = t.intersection([
    PaymentM,
    t.type({
        customerName: t.string,
        participants: t.array(Participant),
    }),
]);

export const ApiResultManagerPayments = t.type({
    payments: t.array(ApiResultManagerPaymentListEntry),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerPaymentListEntry = t.TypeOf<typeof ApiResultManagerPaymentListEntry>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerPayments = t.TypeOf<typeof ApiResultManagerPayments>;
