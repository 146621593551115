import * as t from "io-ts";

import { Message } from "./Message";

export const ApiResultManagerMessages = t.type({
    unread: t.array(Message),
    read: t.array(Message),
    sent: t.array(Message),
    hasMoreUnread: t.boolean,
    hasMoreRead: t.boolean,
    hasMoreSent: t.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerMessages = t.TypeOf<typeof ApiResultManagerMessages>;
