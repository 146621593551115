import * as t from "io-ts";

import { Nullable } from "./Nullable";

export const User = t.type({
    email: t.string,
    jobTitle: Nullable(t.string),
    displayName: t.string,
    avatarUrl: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type User = t.TypeOf<typeof User>;
