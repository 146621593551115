import * as t from "io-ts";

import { MomentDateTime } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const Voucher = t.type({
    id: t.number,
    fromBookingId: t.number,
    usedForBookingId: Nullable(t.number),
    validAmount: Dinero,
    validUntil: MomentDateTime,
    validForFincaUrl: t.string,
    validForFincaName: t.string,
    validForFincaManager: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Voucher = t.TypeOf<typeof Voucher>;
