import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Nullable } from "./Nullable";
import { Salutation } from "./Salutation";

export const IdCardType = t.keyof({
    Personalausweis: null,
    Reisepass: null,
    Sonstiges: null,
    Führerschein: null,
});

export const Participant = t.type({
    id: t.number,
    bookingId: t.number,
    salutation: Nullable(Salutation),
    firstName: Nullable(t.string),
    lastName: Nullable(t.string),
    birthdate: Nullable(MomentDate),
    idCardType: Nullable(IdCardType),
    idCardValue: Nullable(t.string),
    idCardIssueDate: Nullable(t.string),
    hasBirthdayDuringBooking: t.boolean,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Participant = t.TypeOf<typeof Participant>;
