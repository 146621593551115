import * as t from "io-ts";

import { ApartmentPrice } from "./ApartmentPrice";
import { Cancellation } from "./Cancellation";
import { Dinero } from "./Dinero";
import { Ecotasa } from "./Ecotasa";
import { Flight } from "./Flight";
import { GuestbookEntry } from "./GuestbookEntry";
import { Image } from "./Image";
import { ListBookingC, ListBookingM } from "./ListBooking";
import { MomentDate, MomentTime, MomentDateTime } from "./Moment";
import { Nullable } from "./Nullable";
import { Participant } from "./Participant";
import { PaymentM, PaymentC } from "./Payment";
import { Rating } from "./Rating";
import { RentalCarBooked } from "./RentalCarBooked";
import { SpecialCondition } from "./SpecialCondition";
import { SuggestedArrivalDeparture } from "./SuggestedArrivalDeparture";
import { Voucher } from "./Voucher";
import { ManagerC } from "./Manager";

export const BookingC = t.intersection([
    ListBookingC,
    t.type({
        arrival: Nullable(MomentDateTime),
        bookingTime: MomentDateTime,
        cancellation: Nullable(Cancellation),
        departure: Nullable(MomentDateTime),
        ecotasa: t.union([t.undefined, t.null, Ecotasa]),
        ecotasaIncluded: t.boolean,
        end: MomentDate,
        evChargerAvailable: t.boolean,
        evChargerBooked: t.boolean,
        expiry: Nullable(MomentDateTime),
        flightArrival: Nullable(Flight),
        flightDeparture: Nullable(Flight),
        frontpagePicture: Image,
        hasOverlappingCancellation: t.boolean,
        manager: ManagerC,
        managerContactImage: Nullable(Image),
        managerConfirmationTimeEstimate: Nullable(t.string),
        maxDepartureTime: MomentTime,
        message: Nullable(t.string),
        minArrivalTime: MomentTime,
        numAdults: t.number,
        numBabies: t.number,
        numChildren: t.number,
        okCustomer: t.boolean,
        okManager: t.boolean,
        participants: t.array(Participant),
        participantsValid: t.boolean,
        paymentHint: t.string,
        payments: t.array(PaymentC),
        price: Nullable(Dinero),
        rating: Nullable(Rating),
        rentalCarExternal: Nullable(t.string),
        rentalCarInterfaceEnabled: t.boolean,
        rentalCarInterfaceShowWarning: t.boolean,
        rentalCarInterfaceWait24h: t.boolean,
        rentalCarInternal: t.boolean,
        rentalCars: t.array(RentalCarBooked),
        requiresConfirmation: t.boolean,
        start: MomentDate,
        suggestedArrivalDeparture: SuggestedArrivalDeparture,
        travelInsurance: Nullable(t.unknown),
        travelInsuranceExternal: Nullable(t.string),
        travelInsuranceInternal: t.boolean,
        paymentAccount: Nullable(
            t.type({
                accountHolder: t.string,
                bankName: t.string,
                iban: t.string,
                bic: t.string,
                purpose: t.string,
            }),
        ),
    }),
]);

export const BookingM = t.intersection([
    ListBookingM,
    t.type({
        additionalArrangements: Nullable(t.string),
        additionalBedsPrice: Dinero,
        apartmentPrices: t.array(ApartmentPrice),
        arrival: Nullable(MomentDateTime),
        basicPrice: Nullable(Dinero),
        bookingConfirmationPdf: Nullable(t.string),
        bookingConfirmationPdfExists: t.boolean,
        bookingTime: MomentDateTime,
        cancellation: Nullable(Cancellation),
        cancellationConfirmationPdf: Nullable(t.string),
        causedVouchers: t.array(Voucher),
        commentCustomer: Nullable(t.string),
        commentCustomerTranslation: Nullable(t.string),
        commentCustomerTranslationAutomatic: Nullable(t.string),
        commentManager: Nullable(t.string),
        departure: Nullable(MomentDateTime),
        ecotasa: Nullable(Ecotasa),
        ecotasaIncluded: t.boolean,
        flightArrival: Nullable(Flight),
        flightDeparture: Nullable(Flight),
        freeCancellationUntil: Nullable(MomentDateTime),
        furtherAgreements: Nullable(t.string),
        furtherAgreementsEs: Nullable(t.string),
        guestbook: Nullable(GuestbookEntry),
        includedServices: t.string,
        includedServicesEs: t.string,
        isOwn: t.boolean,
        numAdults: t.number,
        numBabies: t.number,
        numChildren: t.number,
        okManagerTime: Nullable(MomentDateTime),
        okManagerUser: Nullable(t.string),
        oldSpecialCondition: Nullable(Dinero),
        oldSpecialConditionName: Nullable(t.string),
        participants: t.array(Participant),
        payments: t.array(PaymentM),
        rating: Nullable(Rating),
        registrationFormPdf: Nullable(t.string),
        servicePrice: Dinero,
        specialConditionPrice: Nullable(Dinero),
        specialConditions: t.array(SpecialCondition),
        usedVouchers: t.array(Voucher),
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingC = t.TypeOf<typeof BookingC>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingM = t.TypeOf<typeof BookingM>;
