import * as t from "io-ts";

import { Nullable } from "./Nullable";

export const Company = t.type({
    id: t.number,
    name: t.string,

    iban: t.string,
    bic: t.string,
    bankOwner: t.string,

    street: t.string,
    postalCode: t.string,
    city: t.string,
    country: t.string,
    telephone: t.string,

    taxId: t.string,
    taxIdName: t.string,
    ceo: t.string,

    registrationNumber: Nullable(t.string),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Company = t.TypeOf<typeof Company>;
