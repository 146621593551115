import React from "react";
import moment, { Moment } from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { NavbarElementProps } from "react-day-picker/types";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "./DateRangePicker.css";

const Navbar: React.FC<
    NavbarElementProps & {
        showMonth: (month: Date) => void;
    }
> = ({ month, onPreviousClick, onNextClick, showPreviousButton, showNextButton, showMonth }) => {
    const startYear = new Date().getFullYear();
    const quickYears = Array.from(Array(3).keys()).map((i) => i + startYear);

    return (
        <div style={{ padding: "1em 1em 0 1em" }}>
            <div className="row">
                <div className="col-auto">
                    <button
                        className="btn btn-sm btn-outline-dark"
                        onClick={() => onPreviousClick()}
                        disabled={!showNextButton}
                    >
                        <i className="fa fa-fw fa-caret-left" />
                    </button>
                </div>
                <div className="col text-center">
                    {quickYears.map((year) => (
                        <button
                            key={year}
                            className="btn btn-sm btn-outline-dark mx-1"
                            onClick={() => showMonth(new Date(year, month.getMonth(), 1))}
                        >
                            {year}
                        </button>
                    ))}
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-sm btn-outline-dark"
                        onClick={() => onNextClick()}
                        disabled={!showNextButton}
                    >
                        <i className="fa fa-fw fa-caret-right" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export type DateRangePickerProps = {
    numberOfMonths?: number;
    inputId?: string;
    start: Moment | null;
    end: Moment | null;
    setStart: (day: Moment | null) => void;
    setEnd: (day: Moment | null) => void;
    onClose?: () => void;
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({ start, end, setStart, setEnd, onClose, ...props }) => {
    const numberOfMonths = props.numberOfMonths ?? 2;
    const dayPickerInputRef = React.useRef<DayPickerInput>(null);

    // DayPickerInput will native Dates statt Moment-Objekte
    // und undefined statt null
    const startDate = start ? start.toDate() : undefined;
    const endDate = end ? end.toDate() : undefined;

    // Helfer zum anzeigen eines bestimmten Monats
    const showMonth = (month: Date) =>
        dayPickerInputRef.current && dayPickerInputRef.current.getDayPicker().showMonth(month);

    // Wir schließen etwas zeitverzögert, damit der Nutzer nochmal kurz
    // optisches Feedback bekommt
    const closeOverlay = () => {
        window.setTimeout(() => {
            if (dayPickerInputRef.current) {
                dayPickerInputRef.current.hideDayPicker();
                onClose && onClose();
            }
        }, 750);
    };

    // Handler für Klick auf einen Tag
    const handleDayClick = (rawDay: Date) => {
        const day = moment(rawDay);
        if (!start) {
            setStart(day);
        } else if (!end) {
            if (day.isBefore(start)) {
                setEnd(start);
                setStart(day);
                closeOverlay();
            } else if (day.isSame(start)) {
                setStart(null);
                setEnd(null);
            } else {
                setEnd(day);
                closeOverlay();
            }
        } else {
            setStart(day);
            setEnd(null);
        }
    };

    // Wert im Eingabefeld des Datepickers
    const enDash = "\u2013";
    const inputValue =
        start && end
            ? `${start.format("L")}${enDash}${end.format("L")}`
            : start
              ? `${start.format("L")}${enDash}`
              : end
                ? `${enDash}${end.format("L")}`
                : "";

    return (
        <div className="DayPickerInput--Range">
            <DayPickerInput
                ref={dayPickerInputRef}
                dayPickerProps={{
                    month: startDate ? startDate : undefined,
                    numberOfMonths: numberOfMonths,
                    selectedDays: [startDate, { from: startDate, to: endDate }],
                    modifiers: { start: startDate, end: endDate },
                    onDayClick: handleDayClick,
                    localeUtils: MomentLocaleUtils,
                    locale: "de",
                    navbarElement: (defaultProps: any) => <Navbar {...defaultProps} showMonth={showMonth} />,
                    disabledDays: [{ before: new Date() }],
                }}
                hideOnDayClick={false}
                onDayPickerHide={onClose}
                component={(inputProps: any) => (
                    <input
                        {...inputProps}
                        className="form-control"
                        value={inputValue}
                        id={props.inputId}
                        placeholder="Zeitraum"
                        autoComplete="off"
                    />
                )}
            />
        </div>
    );
};

export default DateRangePicker;
