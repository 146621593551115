import i18n from "i18next";

import { ApiResponse, ApiErrorType } from "jslib/types";

function getErrorStringFromType(errorType: ApiErrorType): string {
    // Hier wird absichtlich öfter "ServerError" benutzt. Der Nutzer versteht
    // den Unterschied sowieso nicht. Es geht nur darum, dass es nicht seine
    // Schuld ist.
    switch (errorType) {
        case "NetworkError":
            return i18n.t("errors.networkError");
        case "ServerError":
            return i18n.t("errors.serverError");
        case "ParserError":
            return i18n.t("errors.serverError");
        case "NotFoundError":
            return i18n.t("errors.notFoundError");
        case "Cancelled":
            return i18n.t("errors.cancelled");
        case "ForbiddenError":
            return i18n.t("errors.forbidden");
        case "OtherError":
            return i18n.t("errors.unknownError");
    }
}

async function translateApiError<T>(promise: Promise<ApiResponse<T>>): Promise<ApiResponse<T>> {
    const response = await promise;
    if (!response.apiSuccess) {
        response.apiErrorMessage = getErrorStringFromType(response.apiErrorType) || response.apiErrorType;
    }
    return response;
}

export default translateApiError;
