import React from "react";

import getQueryParameter from "./getQueryParameter";
import setQueryParameter from "./setQueryParameter";

/* useStateFromUrl funktioniert wie useState, aber synchronisiert den
 * gespeicherten Wert mit den URL-GET-Parametern. Das heißt, dass der
 * Standardwert von useState initial aus GET übernommen wird. Außerdem
 * wird bei Aufruf von setState der Parameter im GET aktualisiert.
 *
 * Hierfür sind eine Reihe an Einstellungen nötig, da wir den State etwas
 * beschränken wollen. Zuerst übergeben wir den Datentyp als Generic,
 * genau wie in useState, an useStateFromUrl:
 *
 * const [val, setVal] = useStateFromUrl<string>(options);
 *
 * WICHTIG: Im Unterschied zu useState verwendet useState IMMER auch null als
 * möglichen Wert. <string> ist hier also besser als <string | null> zu
 * verstehen.
 *
 * Wie man sieht, wird äquivalent zu useState, der aktuelle Wert und ein Setter
 * zurückgegeben. Die Parameter an useStateFromUrl unterscheiden sich aber. Da
 * es viele Parameter sein können, werden die Parameter als Objekt übergeben.
 *
 * Es folgt die Definition der möglichen Parameter:
 */

type UseStateFromUrlOptions<T> = {
    // Der Name des GET-Parameters, mit welchen dieser Wert synchronisiert
    // werden soll. Muss eindeutig auf der Seite sein, sonst überschreiben sich
    // die States gegenseitig.
    name: string;
    // Der Validator ist eine Funktion, welche überprüft, ob ein Wert im
    // GET-Parameter gültig ist. Nur wenn tatsächlich ein Wert gesetzt ist (also
    // nicht Leerstring und nicht undefined), wird der Validator aufgerufen. Er
    // gibt einfach true/false zurück, um zu entscheiden, ob der Wert gültig
    // ist. Nur ein gültiger Wert wird dann in den State übernommen. Ungültige
    // Werte werden zu null oder zu defaultValue (falls gesetzt).
    validate: (s: string) => boolean;
    // Der Encoder macht aus dem aktuellen State (vom Typ T) einen String,
    // welcher im GET-Parameter gesetzt wird. Der Encoder kann auch null
    // zurückgeben. Dann wird der GET-Parameter aus der URL entfernt.
    encode: (a: T) => string | null;
    // Der Decoder macht das Gegenteil: Er macht aus einem String aus dem
    // GET-Parameter ein Wert vom Typ, welcher dann im State gespeichert werden
    // kann. Der Decoder wird nur aufgerufen, wenn der Validator true
    // zurückgegeben hat.
    decode: (s: string) => T;
    // Falls remove true ist, wird der GET-Parameter immer aus der URL gelöscht.
    // Dieser Parameter kann sich auch bei jedem neuem Rendern ändern.
    remove?: boolean;
    // Der Standardwert ist ähnlich wie bei useState. Wenn der GET-Parameter
    // beim ersten Aufruf ungültig ist, wird dieser Wert benutzt. Der
    // Standardwert wird nicht in die URL übertragen. Setzt man über den Setter
    // den Standardwert, wird der Parameter aus der URL entfernt. Hinweis: ein
    // fehlender GET-Parameter kann trotzdem gültig sein, daher wird dieser Wert
    // nicht immer benutzt.
    defaultValue?: T;
    // Der Initialwert ist ähnlich wie der Standardwert. Es gibt aber ein paar
    // wesentliche Unterschiede:
    // * wenn der GET-Parameter fehlt, wird immer dieser Wert genommen; auch
    //   wenn der leere GET-Parameter eigentlich gültig ist
    // * dieser Wert wird auch in die URL übertragen, wenn der Setter damit
    //   aufgerufen wird
    // * Wird defaultValue UND initialValue übergeben, während der
    //   GET-Parameter ungültig ist, wird initialValue benutzt
    initialValue?: T;
};

export default function useStateFromUrl<T>(options: UseStateFromUrlOptions<T>): [T | null, (a: T | null) => void] {
    const urlValueExists = getQueryParameter(options.name) !== null;
    const urlValueRaw: string = getQueryParameter(options.name) || "";
    const urlIsValid: boolean = options.validate(urlValueRaw);
    const urlValue: T | null = urlIsValid ? options.decode(urlValueRaw) : null;
    const [state, setState] = React.useState<T | null>(
        !urlValueExists && options.initialValue !== undefined
            ? options.initialValue
            : urlIsValid
              ? urlValue
              : options.defaultValue !== undefined
                ? options.defaultValue
                : null,
    );

    // Nur beim ersten Aufruf: ungültige URL-Parameter und default-Werte entfernen
    React.useEffect(
        () => {
            if (
                (!urlIsValid && urlValueExists) ||
                (options.defaultValue !== undefined && state === options.defaultValue)
            ) {
                setQueryParameter(window.location.href, options.name, null);
            }
        },
        // Wir wollen das ganz explizit nur beim ersten Render ausführen
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // Immer: wenn remove gesetzt ist, Parameter ausblenden
    React.useEffect(() => {
        if (options.remove) {
            setQueryParameter(window.location.href, options.name, null);
        }
    }, [options.name, options.remove]);

    // Immer beim Aufruf des Setters
    const mySetter = React.useCallback(
        (newState: T | null) => {
            if (options.defaultValue !== undefined && newState === options.defaultValue) {
                // Falls der Standardwert gesetzt wurde, entfernen wir ihn aus der
                // URL
                setQueryParameter(window.location.href, options.name, null);
            } else if (newState !== state) {
                // Sonst setzen wir den Wert, wenn er nicht leer ist
                setQueryParameter(
                    window.location.href,
                    options.name,
                    newState === null ? null : options.encode(newState),
                );
            }
            setState(newState);
        },
        [state, options],
    );

    return [state, mySetter];
}
