import * as t from "io-ts";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const Service = t.type({
    amount: t.number,
    description: t.string,
    descriptionEs: Nullable(t.string),
    id: t.number,
    localPayment: t.boolean,
    name: t.string,
    nameEs: Nullable(t.string),
    price: Dinero,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Service = t.TypeOf<typeof Service>;
