import * as t from "io-ts";

import { RentalCarRate } from "./RentalCarRate";

export const RentalCarBooked = t.type({
    Rate: RentalCarRate,
    Code: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarBooked = t.TypeOf<typeof RentalCarBooked>;
