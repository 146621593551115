import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const ApartmentPriceBase = t.type({
    end: MomentDate,
    start: MomentDate,
    value: Nullable(Dinero),
});

export const ApartmentPrice = t.intersection([
    ApartmentPriceBase,
    t.type({
        numBookings: t.number,
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApartmentPriceBase = t.TypeOf<typeof ApartmentPriceBase>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApartmentPrice = t.TypeOf<typeof ApartmentPrice>;
