import * as t from "io-ts";
import { either } from "fp-ts/Either";
import moment from "moment";

export const MomentDateTime = new t.Type<moment.Moment, string>(
    "Moment",
    (mixed): mixed is moment.Moment => moment.isMoment(mixed),
    (mixed, context) =>
        either.chain(t.string.validate(mixed, context), (str) => {
            const instance = moment(str, moment.ISO_8601);
            return instance.isValid() ? t.success(instance) : t.failure(str, context);
        }),
    (instance) => instance.toISOString(),
);

export const MomentDate = new t.Type<moment.Moment, string>(
    "Moment",
    (mixed): mixed is moment.Moment => moment.isMoment(mixed),
    (mixed, context) =>
        either.chain(t.string.validate(mixed, context), (str) => {
            const instance = moment(str, "YYYY-MM-DD");
            return instance.isValid() ? t.success(instance) : t.failure(str, context);
        }),
    (instance) => instance.format("YYYY-MM-DD"),
);

export const MomentTime = new t.Type<moment.Moment, string>(
    "Moment",
    (mixed): mixed is moment.Moment => moment.isMoment(mixed),
    (mixed, context) =>
        either.chain(t.string.validate(mixed, context), (str) => {
            const instance = moment(str, "HH:mm");
            return instance.isValid() ? t.success(instance) : t.failure(str, context);
        }),
    (instance) => instance.toISOString(),
);

export const MomentDuration = new t.Type<moment.Duration, string>(
    "Moment",
    (mixed): mixed is moment.Duration => moment.isMoment(mixed),
    (mixed, context) =>
        either.chain(t.number.validate(mixed, context), (str) => {
            const instance = moment.duration(str, "seconds");
            return instance.isValid() ? t.success(instance) : t.failure(str, context);
        }),
    (instance) => instance.toISOString(),
);

export const MomentTimestamp = new t.Type<moment.Moment, number>(
    "Moment",
    (mixed): mixed is moment.Moment => moment.isMoment(mixed),
    (mixed, context) =>
        either.chain(t.number.validate(mixed, context), (num) => {
            const instance = moment.unix(num);
            return instance.isValid() ? t.success(instance) : t.failure(num, context);
        }),
    (instance) => instance.unix(),
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MomentDateTime = moment.Moment;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MomentDate = moment.Moment;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MomentTime = moment.Moment;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MomentDuration = moment.Duration;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MomentTimestamp = moment.Moment;
