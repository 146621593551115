import * as t from "io-ts";
import { either } from "fp-ts/Either";
import DineroJS from "dinero.js";

export const Dinero = new t.Type<Dinero.Dinero, string>(
    "Dinero",
    (mixed: any): mixed is Dinero.Dinero =>
        typeof mixed === "object" &&
        typeof mixed?.convertPrecision === "function" &&
        typeof mixed?.getAmount === "function" &&
        typeof mixed?.toObject === "function" &&
        typeof mixed?.toJSON === "function",
    (mixed, context) =>
        either.chain(t.string.validate(mixed, context), (str) => {
            const instance = DineroJS({ amount: parseInt(str.replace(".", "")) });
            return t.success(instance);
        }),
    (instance) => {
        const asStr = String(instance.convertPrecision(2).getAmount());
        const euros = asStr.slice(0, -2) || "0";
        const cents = asStr.slice(-2).length === 2 ? asStr.slice(-2) : "0" + asStr.slice(-2);
        return euros + "." + cents;
    },
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Dinero = Dinero.Dinero;
