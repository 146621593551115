import * as t from "io-ts";

export const Breakpoint = t.keyof({
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Breakpoint = t.TypeOf<typeof Breakpoint>;
