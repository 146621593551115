import React from "react";
import clsx from "clsx";

type BootstrapButtonClasses =
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";

type ToggleButtonGroupOption<T> = {
    label: string;
    value: T;
};

type ToggleButtonGroupOptions<T> = ToggleButtonGroupOption<T>[];

type ToggleButtonGroupProps<T> = {
    buttonClass: BootstrapButtonClasses;
    options: ToggleButtonGroupOptions<T>;
    value: T;
    setValue: (value: T) => void;
    isSmall?: boolean;
    fullWidth?: boolean;
    className?: string;
};

const ToggleButtonGroup = <T,>({
    buttonClass,
    options,
    value,
    setValue,
    isSmall,
    fullWidth,
    className,
}: React.PropsWithChildren<ToggleButtonGroupProps<T>>) => (
    <div
        className={clsx(
            {
                "btn-group btn-group-toggle": true,
                "btn-block": fullWidth,
                "btn-group-sm": isSmall,
            },
            className,
        )}
    >
        {options.map((option, idx) => (
            <label
                key={idx}
                className={clsx({
                    btn: true,
                    [`btn-outline-${buttonClass}`]: option.value !== value,
                    [`btn-${buttonClass}`]: option.value === value,
                })}
                style={{ flexGrow: option.value === value ? 1.3 : undefined, transition: "flex-grow 200ms" }}
            >
                <input type="radio" defaultChecked={value === option.value} onClick={() => setValue(option.value)} />{" "}
                {option.value === value && <i className="fa fa-fw fa-check" />}
                {option.label}
            </label>
        ))}
    </div>
);

export default ToggleButtonGroup;
