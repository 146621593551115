import * as t from "io-ts";

import { MomentDate, MomentDateTime } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";
import { BookingStatus } from "./BookingStatus";
import { HasAdditionalBed } from "./HasAdditionalBed";
import { Service } from "./Service";
import { RequiresConfirmationReason } from "./RequiresConfirmationReason";

export const ListBookingC = t.type({
    id: t.number,
    start: MomentDate,
    end: MomentDate,
    expiry: Nullable(MomentDateTime),
    fincaName: t.string,
    apartmentName: t.string,
    isAgritourism: t.boolean,
    isBookable: t.boolean,
    authTokenUrl: t.string,
    fincaIsVisible: t.boolean,
    hasRating: t.boolean,
});

export const ListBookingM = t.type({
    canMarkRead: t.boolean,
    end: MomentDate,
    start: MomentDate,
    expiry: Nullable(MomentDateTime),
    hasAdditionalBeds: t.array(HasAdditionalBed),
    id: t.number,
    isUnread: t.boolean,
    okCustomer: t.boolean,
    numAdults: t.number,
    numBabies: t.number,
    numChildren: t.number,
    status: BookingStatus,
    price: Nullable(Dinero),
    requiresConfirmationReason: Nullable(RequiresConfirmationReason),
    okManager: t.boolean,
    services: t.array(Service),
    cancelledBy: Nullable(
        t.keyof({
            Kunde: null,
            Vermieter: null,
            "Auf nach Mallorca": null,
        }),
    ),
    cancellationReason: Nullable(t.string),
    cancellationReasonEs: Nullable(t.string),
    commentManager: Nullable(t.string),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ListBookingC = t.TypeOf<typeof ListBookingC>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ListBookingM = t.TypeOf<typeof ListBookingM>;
