import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { Message } from "./Message";
import { ListBookingM } from "./ListBooking";
import { CustomerM } from "./Customer";
import { Finca } from "./Finca";
import { Apartment } from "./Apartment";
import { Rating } from "./Rating";
import { GuestbookEntry } from "./GuestbookEntry";
import { Invoice } from "./Invoice";
import { PaymentM } from "./Payment";
import { IcalSync } from "./IcalSync";
import { NewFeature } from "./FincaFeatures";

export const MessageNews = t.type({
    type: t.literal("message"),
    message: Message,
});

export const BookingNews = t.type({
    type: t.literal("booking"),
    booking: ListBookingM,
    customer: CustomerM,
    finca: Finca,
    apartment: Apartment,
});

export const RatingNews = t.type({
    type: t.literal("rating"),
    bookingId: t.number,
    text: Nullable(t.string),
    manualTranslation: Nullable(t.string),
    automaticTranslation: Nullable(t.string),
    rating: Rating,
    guestbookEntry: GuestbookEntry,
});

export const InvoiceNews = t.type({
    type: t.literal("invoice"),
    invoice: Invoice,
});

export const PaymentNews = t.type({
    type: t.literal("payment"),
    payment: PaymentM,
    customer: CustomerM,
});

export const IcalSyncNews = t.type({
    type: t.literal("icalSync"),
    icalSync: IcalSync,
});

export const NewFeaturesNews = t.type({
    type: t.literal("newFeatures"),
    finca: Finca,
    newFeatures: t.array(NewFeature),
});

export const News = t.union([
    MessageNews,
    BookingNews,
    RatingNews,
    InvoiceNews,
    PaymentNews,
    IcalSyncNews,
    NewFeaturesNews,
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageNews = t.TypeOf<typeof MessageNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingNews = t.TypeOf<typeof BookingNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RatingNews = t.TypeOf<typeof RatingNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceNews = t.TypeOf<typeof InvoiceNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentNews = t.TypeOf<typeof PaymentNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IcalSyncNews = t.TypeOf<typeof IcalSyncNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NewFeaturesNews = t.TypeOf<typeof NewFeaturesNews>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type News = t.TypeOf<typeof News>;
