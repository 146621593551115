import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import Api from "api";
import { SearchResults, SearchFilters } from "types";
import ResultList from "components/ResultList";
import SearchSidebar from "components/SearchSidebar";
import RenderDomElement from "jslib/components/RenderDomElement";
import useEffectNoInitial from "jslib/utils/useEffectNoInitial";
import useInterval from "jslib/utils/useInterval";

function mergeResults(oldResult: SearchResults | undefined, newResult: SearchResults): SearchResults {
    return { ...newResult, results: [...(oldResult?.results || []), ...newResult.results] };
}

const Fincasearch: React.FC<{ serverContent: HTMLElement | null }> = ({ serverContent }) => {
    const [result, setResult] = React.useState<SearchResults>();
    const [searchFilters, setSearchFilters] = React.useState<SearchFilters | null>(null);
    const [error, setError] = React.useState<string | undefined>();
    const [showServerContent, setShowServerContent] = React.useState(!!serverContent);
    const [isLoading, setIsLoading] = React.useState(!showServerContent);
    const hasHadServerContent = React.useRef(!!serverContent);

    // Falls wir eine Doorway-Page ausblenden, wollen wir die URL auf die der
    // Suche setzen
    React.useEffect(() => {
        if (!showServerContent && hasHadServerContent.current) {
            window.history.replaceState({}, "", "/fincasuche" + window.location.search);
        }
    }, [showServerContent]);

    // Wir können die Standardwerte für die Sucheinstellungen aus dem DOM holen
    // und an die Sidebar übergeben.
    const defaultFilters: SearchFilters = React.useMemo(() => {
        const rootDomElement = document.getElementById("fincasearch");
        let defaultFilters = {};
        if (rootDomElement) {
            const searchQuery = rootDomElement.dataset.searchQuery;
            if (searchQuery) {
                try {
                    defaultFilters = JSON.parse(searchQuery);
                } catch (Exception) {
                    // dann halt nich
                }
            }
        }
        return defaultFilters;
    }, []);

    // onChange-Handler für die Sidebar
    const onChange = React.useCallback(
        (filters: SearchFilters, isUserInteraction: boolean) => {
            if (!!serverContent && isUserInteraction) {
                setShowServerContent(false);
            }
            setSearchFilters(filters);
        },
        [serverContent],
    );

    // Handler zum Anfragen an den Server
    const update = React.useCallback((filters: SearchFilters, offset: number, sid: string | undefined) => {
        const loadResults = async () => {
            setError(undefined);
            setIsLoading(true);

            const myquery = { offset, ...filters, sid };
            const response = await Api.search(myquery);

            if (response.apiSuccess) {
                setResult((result) => mergeResults(result, response.data));
            } else if (response.apiErrorType !== "Cancelled") {
                setError(response.apiErrorMessage);
            }

            // Wir blenden die Ladeanmation nur aus, wenn diese Anfrage nicht
            // vom Benutzer abgebrochen wurde (zB. durch schnelles ändern der
            // Filter, bevor das Ergebnis eintraf).
            if (response.apiSuccess || response.apiErrorType !== "Cancelled") {
                setIsLoading(false);
            }
        };
        loadResults();
    }, []);

    // Beim Ändern der Suchfilter neue Suchanfrage auslösen
    const normalizedFilters = JSON.stringify(searchFilters);
    useEffectNoInitial(() => {
        if (searchFilters) {
            // Wenn sich die Filter ändern, löschen wir die alten Ergebnisse,
            // statt die neuen anzufügen (für infinite scroll)
            setResult(undefined);
            update(searchFilters, 0, result?.searchQuery.sid);
        }
    }, [normalizedFilters]);

    // Falls wir vom Server schon Inhalte vorgerendert bekommen haben, zeigen
    // wir nur die Konfigurationsleiste an, führen aber keine Suche aus. Buttons
    // mit der Klasse fincasearch-show-results werden so konfiguriert, dass sie
    // die Suche auslösen.
    React.useEffect(() => {
        if (serverContent) {
            const showResultBtns = serverContent.querySelectorAll(".fincasearch-show-results");
            showResultBtns.forEach((btn) =>
                btn.addEventListener("click", () => {
                    setShowServerContent(false);
                }),
            );
        }
    }, [serverContent]);

    // Infinite Scroll
    const currentOffset = result?.results.length ?? 0;
    const loadMore = React.useCallback(() => {
        if (searchFilters) {
            update(searchFilters, currentOffset, result?.searchQuery.sid);
        }
    }, [searchFilters, currentOffset, update, result?.searchQuery.sid]);
    const [infiniteScrollRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: currentOffset < (result?.numResults ?? Infinity),
        onLoadMore: loadMore,
        disabled: !!error || !!showServerContent || !result,
        rootMargin: "0px 0px 400px 0px",
    });

    // Jede Sekunde schicken wir die Liste der gerade angezeigten Ergebnisse an
    // den Server
    const [displayErrorCount, setDisplayErrorCount] = React.useState(0);
    const alreadyDisplayedIds = React.useMemo(
        () => new Set<number>(),
        // Wir senden die Anzeigedaten immer erneut, wenn sich die Suchfilter
        // ändern
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [normalizedFilters],
    );
    const toBeSentToServerIds = React.useMemo(
        () => new Set<number>(),
        // Wenn sich die Suchfilter ändern, löschen wir auch die noch
        // ausstehenden Anzeigen
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [normalizedFilters],
    );

    const sendToServer = React.useCallback(async () => {
        if (toBeSentToServerIds.size > 0 && displayErrorCount < 10 && result?.searchQuery.sid) {
            const response = await Api.onDisplay(result.searchQuery.sid, Array.from(toBeSentToServerIds));
            if (response.apiSuccess) {
                toBeSentToServerIds.forEach((id) => alreadyDisplayedIds.add(id));
                toBeSentToServerIds.clear();
            } else {
                setDisplayErrorCount((e) => e + 1);
            }
        }
    }, [result?.searchQuery.sid, alreadyDisplayedIds, toBeSentToServerIds, displayErrorCount]);
    useInterval(sendToServer, 1000);

    // Wird immer aufgerufen, wenn ein Suchergebnis im Viewport erscheint
    const onDisplay = React.useCallback(
        (fincaPublicId: number) => {
            if (!alreadyDisplayedIds.has(fincaPublicId)) {
                toBeSentToServerIds.add(fincaPublicId);
            }
        },
        [alreadyDisplayedIds, toBeSentToServerIds],
    );

    // Wird bei Klick auf einen Link aufgerufen, dient zum Tracking
    const onClick = React.useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            if (result?.searchQuery.sid && displayErrorCount < 10) {
                const destination = event.currentTarget?.closest("a")?.href;
                if (destination) {
                    await Api.onClick(result.searchQuery.sid, destination);
                }
            }
        },
        [result?.searchQuery.sid, displayErrorCount],
    );

    return (
        <div className="row">
            <div className="col-md-4 col-xl-3">
                <SearchSidebar
                    onChange={onChange}
                    defaultFilters={defaultFilters}
                    hidePills={showServerContent}
                    searchResult={result}
                />
            </div>
            <div className="col-md-8 col-xl-9">
                {error ? (
                    <div className="alert alert-danger">
                        <h4 className="alert-heading">Leider ist ein Fehler aufgetreten</h4>
                        <hr />
                        <p>{error}</p>
                        <p className="mb-0">Bitte kontaktieren Sie uns, falls Sie Hilfe benötigen.</p>
                    </div>
                ) : showServerContent && serverContent ? (
                    <RenderDomElement domElement={serverContent} />
                ) : !!result ? (
                    <ResultList searchFilters={searchFilters} result={result} onDisplay={onDisplay} onClick={onClick} />
                ) : null}

                {isLoading && <div className="spinner" />}
                <div ref={infiniteScrollRef} />
            </div>
        </div>
    );
};

export default Fincasearch;
