import { isLocalStorageAvailable } from "index";
import React from "react";
import { usePopper } from "react-popper";
import { WishlistFincaData } from "types";
import "./Wishlist.css";

const WishlistItem: React.FC<{ fincadata: WishlistFincaData; removeFinca: () => void }> = ({
    fincadata,
    removeFinca,
}) => (
    <div className="row no-gutters">
        <div className="col-4">
            <a href={fincadata.url}>
                <img
                    src={fincadata.imageUrl}
                    alt={fincadata.name}
                    width="100"
                    height="67"
                    onError={(e) => {
                        const errorUrl = "//via.placeholder.com/100x67";
                        if (process.env.NODE_ENV === "development" && e.currentTarget.src !== errorUrl) {
                            e.currentTarget.src = errorUrl;
                        }
                    }}
                />
            </a>
        </div>
        <div className="col d-flex flex-column justify-content-center">
            <a href={fincadata.url}>
                <h4 className="p-0 m-0">{fincadata.name}</h4>
            </a>
            <span>{fincadata.price}</span>
        </div>
        <div className="col-auto d-flex flex-column justify-content-center">
            <button className="btn btn-link btn-sm text-danger" onClick={removeFinca}>
                <i className="fa fa-fw fa-xmark" />
            </button>
        </div>
    </div>
);

const Wishlist: React.FC<{ possibleFinca?: WishlistFincaData; buttonClasses: string }> = ({
    possibleFinca,
    buttonClasses,
}) => {
    const wishlist = isLocalStorageAvailable ? localStorage.getItem("wishlist") || "[]" : "[]";
    const [fincaItems, setFincaItems] = React.useState(JSON.parse(wishlist));
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
    const [isPopperVisible, setIsPopperVisible] = React.useState<boolean>(false);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: "bottom-end",
    });

    const isInWishlist = possibleFinca && fincaItems.find((item: WishlistFincaData) => item.url === possibleFinca.url);

    // Bei Änderung des Merkzettels, speichern wir diesen im localStorage
    React.useEffect(() => {
        if (isLocalStorageAvailable) {
            localStorage.setItem("wishlist", JSON.stringify(fincaItems));
        }
    }, [fincaItems]);

    // Falls wir das Popup öffnen, müssen wir verhindern, dass die Navbar
    // auf Mobilgeräten ausgeblendet wird
    React.useEffect(() => {
        if ("setForceShowNavbar" in window) {
            (window as any).setForceShowNavbar(isPopperVisible);
        }
    }, [isPopperVisible]);

    // Callbacks für den Merkzettel
    const addPossibleFinca = React.useCallback(() => {
        if (possibleFinca) {
            let newItems = fincaItems.filter((item: WishlistFincaData) => item.url !== possibleFinca.url);
            newItems = [...newItems, possibleFinca];
            setFincaItems(newItems);

            if ((window as any)._paq) {
                (window as any)._paq.push(["trackGoal", 5]);
            }
        }
    }, [possibleFinca, fincaItems]);

    const removeFinca = React.useCallback(
        (url: string) => {
            const newItems = fincaItems.filter((item: WishlistFincaData) => item.url !== url);
            setFincaItems(newItems);
        },
        [fincaItems],
    );

    // Wenn man außerhalb des Containers klickt, schließen wir das Popup. Aber
    // nur, wenn das Klick-Target Teil des Dokuments ist. (Wenn man per "X" ein
    // Item entfernt, wird der Eintrag mitsamt Button ggf. schon entfernt,
    // sodass der Button dann nicht mehr Teil des Containers ist)
    const handleDocumentClick = React.useCallback(
        function (this: Document, event: MouseEvent | TouchEvent) {
            if (
                containerRef.current?.contains(event.target as Node) === false &&
                document.contains(event.target as Node)
            ) {
                setIsPopperVisible(false);
            }
        },
        [containerRef],
    );

    React.useEffect(() => {
        if (isPopperVisible) {
            document.addEventListener("click", handleDocumentClick);
            document.addEventListener("touchend", handleDocumentClick);
            return () => {
                document.removeEventListener("click", handleDocumentClick);
                document.removeEventListener("touchend", handleDocumentClick);
            };
        }
    }, [handleDocumentClick, isPopperVisible]);

    return (
        <div ref={containerRef}>
            <button
                className={buttonClasses}
                title="Merkzettel"
                ref={setReferenceElement}
                onClick={() => {
                    update && update();
                    setIsPopperVisible(!isPopperVisible);
                }}
            >
                <i className="fa fa-fw fa-heart" />
            </button>

            {isPopperVisible && (
                <div
                    id="wishlist-popper"
                    ref={setPopperElement}
                    {...attributes.popper}
                    className="popover p-2"
                    style={{ ...styles.popper }}
                >
                    {!isLocalStorageAvailable ? (
                        <div className={`popover-body${fincaItems.length > 0 ? " mb-2" : ""}`}>
                            Auf Ihrem Gerät ist der Merkzettel leider nicht verfügbar.
                        </div>
                    ) : fincaItems.length === 0 ? (
                        <div className={`popover-body${fincaItems.length > 0 ? " mb-2" : ""}`}>
                            Bisher sind keine Fincas auf dem Merkzettel.
                        </div>
                    ) : null}

                    {fincaItems.length > 0 && (
                        <div className="wishlist">
                            {fincaItems.map((finca: WishlistFincaData) => (
                                <WishlistItem
                                    key={finca.url}
                                    fincadata={finca}
                                    removeFinca={() => removeFinca(finca.url)}
                                />
                            ))}
                        </div>
                    )}

                    {isInWishlist ? (
                        <button
                            className="btn btn-outline-dark btn-block mt-2"
                            onClick={() => removeFinca(possibleFinca!.url)}
                        >
                            <i className="fa fa-fw fa-heart" /> {possibleFinca!.name} entfernen
                        </button>
                    ) : !!possibleFinca ? (
                        <button className="btn btn-outline-primary btn-block mt-2" onClick={addPossibleFinca}>
                            <i className="fa fa-fw fa-heart" /> {possibleFinca.name} merken
                        </button>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default Wishlist;
