import * as t from "io-ts";

import { ImageTupleBreakpointsFull, MomentDate, Dinero, Nullable, Optional, OptionalNullable } from "jslib/types";

import { SearchObjectType, SearchFilters } from "./SearchFilters";

export const SearchResultApartment = t.type({
    name: t.string,
    numBeds: t.number,
    type: t.string,
    url: t.string,
    publicId: t.number,
    previewGallery: t.array(ImageTupleBreakpointsFull),
});

export const SearchResultFinca = t.type({
    id: t.number,
    publicId: t.number,
    mainImage: Nullable(ImageTupleBreakpointsFull),
    name: t.string,
    url: t.string,
    searchObjectType: SearchObjectType,
    numApartments: t.number,
    numBeds: t.number,
    maxAdditionalBeds: t.number,
    numBedrooms: t.number,
    numBathrooms: t.number,
    searchLines: t.array(t.string),
    cancellationAdText: OptionalNullable(t.string),
    isNew: t.boolean,
    objectType: t.string,
    rating: t.type({
        average: t.number,
        count: t.number,
        serviceKindnessCarrier: Nullable(t.number),
        cleannessInside: Nullable(t.number),
        cleannessOutside: Nullable(t.number),
        priceQuality: Nullable(t.number),
        furniture: Nullable(t.number),
        serviceBroker: Nullable(t.number),
    }),
    previewGallery: t.array(ImageTupleBreakpointsFull),
    pricerange: t.tuple([Nullable(Dinero), Nullable(Dinero)]),
    apartments: t.record(t.string, SearchResultApartment),
    guestbookPreview: Nullable(t.string),
    guestbookPreviewAuthor: Nullable(t.string),
});

export const SearchResultBooking = t.type({
    start: MomentDate,
    end: MomentDate,
    price: Dinero,
    specialConditionPrice: Dinero,
    apartmentPublicId: t.number,
    adults: t.number,
    children: t.number,
    babies: t.number,
});

export const SearchResult = t.type({
    finca: SearchResultFinca,
    bookings: t.array(SearchResultBooking),
    sortIndex: Optional(t.tuple([t.number, t.record(t.string, t.number)])),
    hasMore: t.boolean,
    isExtended: Optional(t.boolean),
});

export const SearchResults = t.type({
    results: t.array(SearchResult),
    numResults: t.number,
    searchQuery: SearchFilters,
    restrictCounts: t.record(t.string, t.record(t.string, t.number)),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchResultFinca = t.TypeOf<typeof SearchResultFinca>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchResultBooking = t.TypeOf<typeof SearchResultBooking>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchResultApartment = t.TypeOf<typeof SearchResultApartment>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchResult = t.TypeOf<typeof SearchResult>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchResults = t.TypeOf<typeof SearchResults>;
