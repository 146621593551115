import React from "react";

import { SearchResult } from "types";

const SortIndexDebug: React.FC<{ result: SearchResult }> = ({ result }) =>
    result.sortIndex !== undefined ? (
        <div className="dropdown">
            <button
                className="btn btn-outline-dark dropdown-toggle mr-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            ></button>
            <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                <table className="table table-sm mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Kriterium</th>
                            <th scope="col">Index</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(result.sortIndex[1]).map(([func, idx]) => (
                            <tr key={func}>
                                <td className="text-monospace text-nowrap">{func}</td>
                                <td className="text-monospace">{idx.toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr>
                            <th scope="row">Summe</th>
                            <td className="text-monospace">{result.sortIndex[0].toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;

export default SortIndexDebug;
