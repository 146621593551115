import * as t from "io-ts";
import { ListBookingC } from "./ListBooking";
import { Voucher } from "./Voucher";
import { CustomerC } from "./Customer";
import { Participant } from "./Participant";

export const KundenloginHomeLoggedIn = t.type({
    isLoggedIn: t.literal(true),
    bookingList: t.array(ListBookingC),
    offerList: t.array(ListBookingC),
    onlineBookingList: t.array(ListBookingC),
    vouchers: t.array(Voucher),
    message: t.string,
    customer: CustomerC,
    canSu: t.union([t.undefined, t.boolean]),
    csrfToken: t.string,
    allParticipants: t.array(Participant),
});

export const KundenloginHomeUnauthenticated = t.type({
    isLoggedIn: t.literal(false),
});

export const KundenloginHome = t.union([KundenloginHomeUnauthenticated, KundenloginHomeLoggedIn]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type KundenloginHome = t.TypeOf<typeof KundenloginHome>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type KundenloginHomeLoggedIn = t.TypeOf<typeof KundenloginHomeLoggedIn>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type KundenloginHomeUnauthenticated = t.TypeOf<typeof KundenloginHomeUnauthenticated>;
