import * as t from "io-ts";

export const SuResponseSuccess = t.type({
    ok: t.literal(true),
});

export const SuResponseFailed = t.type({
    ok: t.literal(false),
    error: t.string,
});

export const SuResponse = t.union([SuResponseSuccess, SuResponseFailed]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuResponse = t.TypeOf<typeof SuResponse>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuResponseFailed = t.TypeOf<typeof SuResponseFailed>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuResponseSuccess = t.TypeOf<typeof SuResponseSuccess>;
