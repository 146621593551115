import * as t from "io-ts";

export const Region = t.type({
    id: t.number,
    name: t.string,
    municipalityNumber: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Region = t.TypeOf<typeof Region>;
