import * as t from "io-ts";

export const Country = t.type({
    iso2: t.string,
    name: t.string,
    nameDe: t.string,
    phone: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Country = t.TypeOf<typeof Country>;
