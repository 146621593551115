import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { RentalCarVehicle } from "./RentalCarVehicle";

export const RentalCarValue = t.type({
    amount: t.number,
});

export const RentalCarDepositHandling = t.type({
    LocalValue: RentalCarValue,
    PaymentMethod: t.union([t.literal("CreditCard"), t.literal("DebitCash"), t.literal("None")]),
});

export const RentalCarService = t.type({
    Code: t.string,
    Id: t.number,
});

export const RentalCarRateServiceType = t.type({
    Code: Nullable(t.string),
    Id: t.number,
    Name: t.string,
    NameDisplay: t.string,
    ServiceList: t.array(RentalCarService),
});

export const RentalCarRate = t.type({
    Code: t.string,
    FleetNumber: t.number,
    BaseValue: RentalCarValue,
    Value: RentalCarValue,
    Vehicle: RentalCarVehicle,
    RateServiceType: RentalCarRateServiceType,
    DepositHandling: t.array(RentalCarDepositHandling),
    // Wird vom NCMS hinzugefügt
    IsShuttleService: t.union([t.boolean, t.undefined]),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarValue = t.TypeOf<typeof RentalCarValue>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarRate = t.TypeOf<typeof RentalCarRate>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarRateServiceType = t.TypeOf<typeof RentalCarRateServiceType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarService = t.TypeOf<typeof RentalCarService>;
