import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { Dinero } from "./Dinero";
import { MomentDate } from "./Moment";
import { Finca } from "./Finca";

export const ApiResultManagerStatistics = t.type({
    availableFincas: t.array(Finca),
    availableYears: t.array(t.number),
    numAllGuests: t.number,
    numBookingsAnm: t.number,
    numBookingsManager: t.number,
    numBookingsTotal: t.number,
    numNightsAnm: t.number,
    numNightsManager: t.number,
    numNightsTotal: t.number,
    numRegularGuests: t.number,
    regularGuestsPercentage: Nullable(t.number),
    sales: Dinero,
    salesLastYear: Dinero,
    salesLastYearTotal: Dinero,
    salesLastYearUntil: MomentDate,
    salesPercentage: Nullable(t.number),
    salesUntilDate: MomentDate,
    selectedFinca: Finca,
    selectedYear: t.number,
    sumPerMonth: Nullable(t.array(t.type({ month: MomentDate, sum: Dinero }))),
    utilization: Nullable(t.number),
    utilizationLastYearTotal: Nullable(t.number),
    utilizationLastYearUntil: Nullable(t.number),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerStatistics = t.TypeOf<typeof ApiResultManagerStatistics>;
