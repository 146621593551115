import * as t from "io-ts";

import { Optional } from "./Nullable";
import { MomentDate, MomentDateTime } from "./Moment";

export const ApiResultManagerUpdateBooking = t.type({
    ok: t.boolean,
});

export const ApiResultManagerUpdateBookingData = t.type({
    bookingId: t.number,
    start: MomentDate,
    end: MomentDate,
    expiry: Optional(MomentDateTime),
    apartmentId: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerUpdateBooking = t.TypeOf<typeof ApiResultManagerUpdateBooking>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerUpdateBookingData = t.TypeOf<typeof ApiResultManagerUpdateBookingData>;
