import * as t from "io-ts";

import { Optional } from "./Nullable";

export const ApiResultManagerResetPasswordErrorType = t.keyof({
    "invalid-key": null,
    "too-short": null,
    "letters-and-digits": null,
    "name-parts": null,
    "wrong-password": null,
});

export const ApiResultManagerChangePassword = t.type({
    errorType: Optional(ApiResultManagerResetPasswordErrorType),
    minPasswordLen: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerResetPasswordErrorType = t.TypeOf<typeof ApiResultManagerResetPasswordErrorType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerChangePassword = t.TypeOf<typeof ApiResultManagerChangePassword>;
