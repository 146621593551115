import React from "react";
import Dinero from "dinero.js";

import { RentalCarRate, MomentDate } from "jslib/types";

const Rate: React.FC<{ rate: RentalCarRate; start: MomentDate; end: MomentDate }> = ({ rate, start, end }) => {
    const baseValue = Dinero(rate.BaseValue);
    const value = Dinero(rate.Value);

    return (
        <div className="row">
            <div className="col-lg-5 col-xl-4 text-center">
                <img
                    style={{ maxWidth: "302px", height: "auto" }}
                    src={
                        rate.Vehicle.LargePictureReference.startsWith("http")
                            ? rate.Vehicle.LargePictureReference.replace(/^https?:/i, "")
                            : "//service.sunnycars.com/" + rate.Vehicle.LargePictureReference
                    }
                    alt={rate.Vehicle.VehicleCategory.Name}
                />
            </div>

            <div className="col-lg-7 col-xl-8 p-3 d-flex flex-column justify-content-between">
                <div>
                    <header className="d-flex justify-content-between align-items-baseline">
                        <h3 className="mb-0">
                            {rate.Vehicle.Name} <small className="text-dark text-nowrap">oder ähnlich</small>
                        </h3>{" "}
                        <div className="text-right">
                            <span className="badge badge-success">Kostenlos stornierbar</span>
                        </div>
                    </header>
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h2 className="h6 mb-0">
                            {rate.Vehicle.VehicleCategory.Name} {rate.Vehicle.VehicleBuild.Name}{" "}
                            <small className="text-muted font-italic">("{rate.Vehicle.Code}")</small>
                        </h2>{" "}
                        {rate.Vehicle.Availability === "Confirmed" ? (
                            <span className="badge badge-success">Sofort verfügbar</span>
                        ) : (
                            <span className="badge badge-light">Auf Anfrage</span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-auto d-flex flex-column justify-content-center align-items-center mb-3 mb-md-0">
                        <h3 className="h1 mt-3 mt-xl-0 mb-0">
                            {!baseValue.isZero && (
                                <span className="text-muted" style={{ textDecoration: "line-through" }}>
                                    {baseValue.toFormat()}
                                </span>
                            )}
                            {value.toFormat()}
                        </h3>
                        <small>
                            {start.format("L")}
                            {"\u2013"}
                            {end.format("L")}
                        </small>
                        {rate.DepositHandling.length === 1 && rate.DepositHandling[0].LocalValue.amount === 0 ? (
                            <small>
                                <i className="fa-regular fa-fw fa-thumbs-up" /> Keine Kaution!
                            </small>
                        ) : (
                            rate.DepositHandling.map((deposit, idx) => (
                                <small key={idx}>
                                    {idx > 0 && " oder "}
                                    {deposit.PaymentMethod === "DebitCash"
                                        ? "Barkaution auf Anfrage: "
                                        : "Kaution mit Kreditkarte: "}
                                    {Dinero(deposit.LocalValue).toFormat()}
                                </small>
                            ))
                        )}
                    </div>

                    <div className="col-md d-flex flex-column justify-content-end align-items-end">
                        Buchbar im Anschluss
                        <br />
                        an Ihre Fincabuchung
                    </div>
                </div>
            </div>
        </div>
    );
};

const RentalCars: React.FC<{ rates: RentalCarRate[]; start: MomentDate; end: MomentDate }> = ({
    start,
    end,
    rates,
}) => (
    <>
        <div className="card card-body mb-3">
            <h2 className="h4">
                Mietwagen vom {start.format("L")} bis zum {end.format("L")}
            </h2>

            <div>
                <a href="https://www.sunnycars.de/impressum/?ak=969936" target="_blank" rel="noreferrer">
                    <img
                        className="ml-3 mb-3 float-right"
                        src="/ncms/static/img/logos/sunnycars-102.png"
                        height="72"
                        alt="Sunny Cars Autovermietung"
                    />
                </a>

                <p>
                    Kennen Sie schon die günstigen Mietwagen-Angebote unseres Partners Sunny Cars?
                    <br />
                    <a
                        href="https://www.sunnycars.de/impressum/?ak=969936"
                        target="_blank"
                        rel="noreferrer"
                        className="text-nowrap text-muted text-small"
                    >
                        <i className="fa fa-fw fa-external-link" />
                        Anbieterkennzeichnung
                    </a>
                </p>
            </div>

            {rates.slice(0, 2).map((rate) => (
                <Rate key={rate.Code} rate={rate} start={start} end={end} />
            ))}
        </div>
    </>
);

export default RentalCars;
