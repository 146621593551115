import * as t from "io-ts";

import { MomentDate } from "./Moment";

export const IcalEvent = t.type({
    id: t.number,
    externalId: t.string,
    syncId: t.number,
    start: MomentDate,
    end: MomentDate,
    name: t.string,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IcalEvent = t.TypeOf<typeof IcalEvent>;
