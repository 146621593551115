import * as t from "io-ts";

import { ApartmentPrice } from "./ApartmentPrice";
import { BookingRuleSetParameters as BookingRule } from "./BookingRuleSetParameters";

export const Apartment = t.type({
    id: t.number,
    name: t.string,
    prices: t.array(ApartmentPrice),
    bookingRules: t.array(BookingRule),
    numBeds: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Apartment = t.TypeOf<typeof Apartment>;
