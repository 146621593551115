import * as t from "io-ts";

import { ListBookingM } from "./ListBooking";
import { ListCustomerM } from "./Customer";
import { MomentDate } from "./Moment";

export const ApiResultManagerBookingStatusFilter = t.keyof({
    request: null,
    booking: null,
    reservation: null,
    own: null,
    cancelation: null,
});

export const ApiResultManagerBookingsFilters = t.partial({
    apartmentId: t.number,
    fincaId: t.number,
    bookingStatus: ApiResultManagerBookingStatusFilter,
    customerId: t.number,
    bookingId: t.number,
    startDate: MomentDate,
    endDate: MomentDate,
});

export const ApiResultManagerBookingsListType = t.keyof({
    new: null,
    upcoming: null,
    all: null,
});

export const ApiResultManagerBookingsListEntry = t.type({
    booking: ListBookingM,
    customer: ListCustomerM,
    apartmentName: t.string,
});

export const ApiResultManagerBookings = t.type({
    bookings: t.array(ApiResultManagerBookingsListEntry),
    numBookings: t.number,
    type: ApiResultManagerBookingsListType,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBookings = t.TypeOf<typeof ApiResultManagerBookings>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBookingsListType = t.TypeOf<typeof ApiResultManagerBookingsListType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBookingsListEntry = t.TypeOf<typeof ApiResultManagerBookingsListEntry>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBookingsFilters = t.TypeOf<typeof ApiResultManagerBookingsFilters>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBookingStatusFilter = t.TypeOf<typeof ApiResultManagerBookingStatusFilter>;
