import * as t from "io-ts";
import { RentalCarVehicleCategory } from "./RentalCarVehicleCategory";

export const VehicleCategoryCode = t.union([
    t.literal("_ALL"),
    t.literal("A"),
    t.literal("E"),
    t.literal("C"),
    t.literal("I"),
    t.literal("S"),
    t.literal("F"),
]);
export const VehicleCategory = t.type({ Name: t.string, Code: VehicleCategoryCode });

export const VehicleBuildCode = t.union([
    t.literal("_ALL"),
    t.literal("1"),
    t.literal("5"),
    t.literal("7"),
    t.literal("8"),
    t.literal("9"),
]);
export const VehicleBuild = t.type({ Name: t.string, Code: VehicleBuildCode });

export const VehicleConfigurationKey = t.union([
    t.literal("ac"),
    t.literal("a"),
    t.literal("ec"),
    t.literal("4dr"),
    t.literal("2dr"),
]);
export const VehicleConfiguration = t.type({ text: t.string, key: VehicleConfigurationKey });

export const MeetingTypeKey = t.union([t.literal("_ALL"), t.literal("transfer"), t.literal("airport")]);
export const MeetingType = t.type({ text: t.string, key: MeetingTypeKey });

export const RentalCarVehicleFeature = t.type({
    Code: t.string,
    Id: t.number,
    Name: t.string,
});

export const RentalCarVehicle = t.type({
    Code: t.string,
    Availability: t.union([t.literal("Confirmed"), t.literal("Requested")]),
    BaggageCount: t.number,
    DoorCount: t.number,
    HasAirCondition: t.boolean,
    HasAutomaticGear: t.boolean,
    Id: t.number,
    LargePictureReference: t.string,
    Name: t.string,
    PassengerCount: t.number,
    VehicleCategory: RentalCarVehicleCategory,
    VehicleFeature: t.array(RentalCarVehicleFeature),
    VehicleBuild: VehicleBuild,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarVehicle = t.TypeOf<typeof RentalCarVehicle>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarVehicleFeature = t.TypeOf<typeof RentalCarVehicleFeature>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VehicleCategoryCode = t.TypeOf<typeof VehicleCategoryCode>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VehicleBuildCode = t.TypeOf<typeof VehicleBuildCode>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VehicleConfigurationKey = t.TypeOf<typeof VehicleConfigurationKey>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MeetingTypeKey = t.TypeOf<typeof MeetingTypeKey>;
