import * as t from "io-ts";

import { CancellationRule } from "./CancellationRule";
import { Nullable } from "./Nullable";

export const Cancellation = t.type({
    rules: t.array(CancellationRule),
    hint: Nullable(t.string),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Cancellation = t.TypeOf<typeof Cancellation>;
