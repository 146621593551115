import * as t from "io-ts";

import { Calendar } from "./Calendar";

export const ApiResultManagerCalendar = t.type({
    fincas: t.array(t.type({ name: t.string, id: t.number })),
    apartmentNames: t.array(t.string),
    calendar: Calendar,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCalendar = t.TypeOf<typeof ApiResultManagerCalendar>;
