/* eslint-disable @typescript-eslint/no-redeclare */
import * as t from "io-ts";
import { Nullable } from "./Nullable";

export const Wifi = t.type({
    id: t.number,
    ssid: t.string,
    password: Nullable(t.string),
    description: Nullable(t.string),
});

export type Wifi = t.TypeOf<typeof Wifi>;
