import React from "react";
import moment, { Moment } from "moment";
import Cookie from "js-cookie";
import clsx from "clsx";
import { isRight } from "fp-ts/Either";
import Select from "react-select";

import Api from "api";
import {
    SearchFilters,
    SearchObjectType,
    Region,
    REGIONS,
    REGION_GROUPS,
    SearchFeatureResults,
    SearchResults,
    SortOrderType,
} from "types";
import DateRangePicker from "components/DateRangePicker";
import ToggleButtonGroup from "components/ToggleButtonGroup";
import SetFilter from "components/SetFilter";
import SearchFilterPills from "components/SearchFilterPills";

import useStateFromUrl from "utils/useStateFromUrl";
import range from "jslib/utils/range";
import useEffectNoInitial from "jslib/utils/useEffectNoInitial";
import { isLocalStorageAvailable } from "index";

// Typen und Konstanten
type DurationObject = {
    key: string;
    label: string;
    duration: [number, number];
};

export const DURATIONS = [
    {
        key: "3-4",
        label: "3-4 Tage",
        duration: [3, 4],
    },
    {
        key: "5-6",
        label: "5-6 Tage",
        duration: [5, 6],
    },
    {
        key: "7",
        label: "7 Tage",
        duration: [7, 7],
    },
    {
        key: "8-9",
        label: "8-9 Tage",
        duration: [8, 9],
    },
    {
        key: "10",
        label: "10 Tage",
        duration: [10, 10],
    },
    {
        key: "11-13",
        label: "11-13 Tage",
        duration: [11, 13],
    },
    {
        key: "14",
        label: "14 Tage",
        duration: [14, 14],
    },
    {
        key: "m14",
        label: "Länger als 14 Tage",
        duration: [15, 21],
    },
] as DurationObject[];

type Duration = (typeof DURATIONS)[number]["key"];
const DURATION_KEYS: Duration[] = DURATIONS.map((o) => o.key);

export const SEARCH_OBJECT_TYPES: { key: SearchObjectType; label: string }[] = [
    { key: "agritourism", label: "Agrotourismus" },
    { key: "finca", label: "Einzelne Finca" },
    { key: "flat", label: "Ferienhaus/-wohnung" },
    { key: "fincahotel", label: "Fincahotel" },
];

export const SEA_FEATURES: { slug: string; name: string }[] = [
    { slug: "feat-am-meer", name: "am Meer" },
    { slug: "feat-meerblick", name: "Meerblick" },
];

export const SORT_OPTIONS: { value: SortOrderType; label: string }[] = [
    { value: "best", label: "Beste zuerst" },
    { value: "price-asc", label: "Durchschn. Preis (aufsteigend)" },
    { value: "price-desc", label: "Durchschn. Preis (absteigend)" },
    { value: "rating", label: "Bewertung" },
    { value: "newest", label: "Neu im Angebot" },
    { value: "oldest", label: "Am längsten im Angebot" },
];

// Wandelt ein String vom Typ Duration in ein Tupel (von, bis) an Nächten um,
// welches wir im, SearchFilters-Objekt brauchen
function makeDuration(duration: Duration | null): [number, number] | undefined {
    return duration ? DURATIONS.find((o) => o.key === duration)?.duration : undefined;
}

// Prüft, ob ein SearchFilters-Objekt leer ist (keine Filter enthält)
function searchFiltersIsEmpty(searchFilters: SearchFilters) {
    console.log(searchFilters);
    return !(
        searchFilters.text ||
        searchFilters.daterange ||
        searchFilters.features ||
        searchFilters.objectType ||
        searchFilters.participants ||
        searchFilters.region
    );
}

// Holt die allSearchFeatures aus dem localStorage, falls vorhanden
function getDefaultAllSearchFeatures(): SearchFeatureResults | undefined {
    if (isLocalStorageAvailable) {
        const json = window.localStorage.getItem("searchFeatures") || "";
        try {
            const data = JSON.parse(json);
            if (new Date(data.expires) > new Date()) {
                const result = SearchFeatureResults.decode(data.data);
                if (isRight(result)) {
                    return result.right;
                }
            }
        } catch (Exception) {
            // ¯\_(ツ)_/¯
        }
    }
}

// Funktionen für URL-State-Sync
const validateMoment = (s: string) => moment(s, "DD.MM.YYYY", true).isValid();
const encodeMoment = (s: Moment) => s.format("DD.MM.YYYY");
const decodeMoment = (s: string) => moment(s, "DD.MM.YYYY", true);
const identity = (a: any) => a;
const notEmpty = (s: string) => !!s;
const encodeString = (s: string) => (s.length ? s : null);
const decodeString = identity;
const validateInt = (s: string) => /^(0|[1-9]\d*)$/.test(s);
const encodeInt = (s: number) => String(s);
const decodeInt = (s: string) => parseInt(s, 10);
const validateBoolean = (s: string) => s === "1";
const encodeBoolean = (s: boolean) => (s ? "1" : null);
const decodeBoolean = (s: string) => s === "1";
const validateFromList =
    <T extends any[]>(options: T) =>
    (s: string) =>
        options.includes(s);
const validateSet = (validator: (s: string) => boolean) => (s: string) =>
    s
        .split(",")
        .filter(identity)
        .every((t) => validator(t));
const encodeSet =
    <T extends any>(encoder: (a: T) => string | null) =>
    (s: Set<T>): string | null =>
        s.size === 0
            ? null
            : Array.from(s)
                  .map((t) => encoder(t))
                  .filter(identity)
                  .sort()
                  .join(",") || null;
const decodeSet =
    <T extends any>(decoder: (s: string) => T) =>
    <T extends any>(a: string): Set<T> =>
        new Set<T>(
            a
                .split(",")
                .filter(identity)
                .map((t) => decoder(t) as any as T),
        );

// Serialisiert ein Set so, dass es als Abhängigkeit für useCallback oder andere
// Hooks verwendet werden kann.
const serializeSet = (s: Set<any> | null): string | null => s && Array.from(s).sort().join("/");

// Main-Component
const SearchSidebar: React.FC<{
    onChange: (searchFilters: SearchFilters, isUserInteraction: boolean) => void;
    defaultFilters?: SearchFilters;
    hidePills?: boolean;
    searchResult?: SearchResults;
}> = ({ onChange, defaultFilters, hidePills, searchResult }) => {
    // State
    const [text, setText] = useStateFromUrl<string>({
        name: "text",
        validate: notEmpty,
        encode: encodeString,
        decode: decodeString,
    });
    const [start, setStart] = useStateFromUrl<Moment>({
        name: "start",
        validate: validateMoment,
        encode: encodeMoment,
        decode: decodeMoment,
    });
    const [end, setEnd] = useStateFromUrl<Moment>({
        name: "end",
        validate: validateMoment,
        encode: encodeMoment,
        decode: decodeMoment,
    });
    const [isExactSearch, setIsExactSearch] = useStateFromUrl<boolean>({
        name: "exact",
        validate: validateBoolean,
        encode: encodeBoolean,
        decode: decodeBoolean,
        defaultValue: false,
    });
    const [duration, setDuration] = useStateFromUrl<Duration>({
        name: "duration",
        validate: validateFromList(DURATION_KEYS),
        encode: (s: Duration) => (isExactSearch ? null : encodeString(s)),
        decode: decodeString,
        defaultValue: "7",
        remove: !!isExactSearch,
    });
    const [searchObjectTypes, setSearchObjectTypes] = useStateFromUrl<Set<SearchObjectType>>({
        name: "type",
        validate: validateSet(validateFromList(SEARCH_OBJECT_TYPES.map((sot) => sot.key))),
        encode: encodeSet(encodeString),
        decode: decodeSet(decodeString),
        initialValue: new Set(defaultFilters?.objectType),
    });
    const [region, setRegion] = useStateFromUrl<Region>({
        name: "region",
        validate: validateFromList(REGIONS),
        encode: encodeString,
        decode: decodeString,
    });
    const [numAdults, setNumAdults] = useStateFromUrl<number>({
        name: "adults",
        validate: validateInt,
        encode: encodeInt,
        decode: decodeInt,
    });
    const [numChildren, setNumChildren] = useStateFromUrl<number>({
        name: "children",
        validate: validateInt,
        encode: encodeInt,
        decode: decodeInt,
    });
    const [numBabies, setNumBabies] = useStateFromUrl<number>({
        name: "babies",
        validate: validateInt,
        encode: encodeInt,
        decode: decodeInt,
    });
    const [allSearchFeatures, setAllSearchFeatures] = React.useState<SearchFeatureResults | undefined>(
        getDefaultAllSearchFeatures(),
    );
    const [searchFeatures, setSearchFeatures] = useStateFromUrl<Set<string>>({
        name: "features",
        validate: validateSet(notEmpty),
        encode: encodeSet(encodeString),
        decode: decodeSet(decodeString),
        defaultValue: new Set(),
        initialValue: new Set(defaultFilters?.features),
    });
    const [sortOrder, setSortOrder] = useStateFromUrl<SortOrderType>({
        name: "sort",
        validate: validateFromList(SORT_OPTIONS.map((o) => o.value)),
        encode: encodeString,
        decode: decodeString,
        defaultValue: SORT_OPTIONS[0].value,
    });

    // Helfer
    const makeSearchFilters = (): SearchFilters => {
        // Wenn Text eingegeben wurde ignorieren wir die anderen Filter
        if (text && text.length) {
            return { text };
        } else {
            return {
                daterange:
                    start && end
                        ? {
                              start,
                              end,
                              duration: isExactSearch ? undefined : makeDuration(duration),
                          }
                        : undefined,
                objectType: searchObjectTypes && searchObjectTypes.size ? Array.from(searchObjectTypes) : undefined,
                region: region || undefined,
                limit: 10,
                participants:
                    numAdults || numChildren || numBabies
                        ? {
                              adults: numAdults || 0,
                              children: numChildren || 0,
                              babies: numBabies || 0,
                          }
                        : undefined,
                features: searchFeatures && searchFeatures.size ? Array.from(searchFeatures) : undefined,
                orderBy: sortOrder || undefined,
            };
        }
    };

    // Wir merken uns die letzten Suchfilter für die Anzeige der SearchFilterPills
    const [currentSearchFilters, setCurrentSearchFilters] = React.useState<SearchFilters>(makeSearchFilters());

    const callOnChange = (isUserInteraction: boolean) => {
        const searchFilters = makeSearchFilters();
        onChange(searchFilters, isUserInteraction);
        setCurrentSearchFilters(searchFilters);
    };

    // Falls der Kunde im Datenpicker ein Datum setzt (also per Klick, explizit
    // NICHT nur per URL), dann setzen wir ihm ein Cookie auf seinen
    // Reisezeitraum. Außerdem setzen wir nur dann eine neue Suchanfrage ab,
    // wenn der Kunde den Picker schließt.
    const hasStartAndEnd = React.useRef(!!start && !!end);
    const onCloseDateRangePicker = () => {
        if (start && end) {
            hasStartAndEnd.current = true;
            Cookie.set("arrival", start.format("YYYY-MM-DD"), { expires: 7 });
            Cookie.set("departure", end.format("YYYY-MM-DD"), { expires: 7 });
            callOnChange(true);
        } else if (!start && !end && hasStartAndEnd.current) {
            hasStartAndEnd.current = false;
            Cookie.remove("arrival");
            Cookie.remove("departure");
            callOnChange(true);
        }
    };

    // Beim ersten Öffnen laden wir alle SearchFeatures vom Server. Sobald wir
    // die SearchFeatures haben, überprüfen wir, ob die aktuell in der URL
    // angegeben Werte alle gültig sind.
    const loadAllSearchFeatures = React.useCallback(async () => {
        const result = await Api.getSearchFeatures();

        if (result.apiSuccess) {
            // Filter nach validaten URL-Einträgen
            setAllSearchFeatures(result.data);
            const allowedKeys: string[] = [...result.data.aggregates, ...result.data.features]
                .map((sf) => sf.slug)
                .concat(SEA_FEATURES.map((s) => s.slug));
            const currentFilters: string[] = Array.from(searchFeatures || []);
            const newFeaturesArray: string[] = currentFilters.filter((sf) => allowedKeys.includes(sf));
            const newFeaturesSet = new Set(newFeaturesArray);
            if (newFeaturesSet.size < (searchFeatures ? searchFeatures.size : 0)) {
                setSearchFeatures(newFeaturesSet);
            }

            // Cache
            if (isLocalStorageAvailable) {
                window.localStorage.setItem(
                    "searchFeatures",
                    JSON.stringify({
                        data: SearchFeatureResults.encode(result.data),
                        expires: new Date(new Date().getTime() + 60 * 60000), // 60 minutes
                    }),
                );
            }
        }
    }, [searchFeatures, setSearchFeatures]);

    React.useEffect(
        () => {
            if (!allSearchFeatures) {
                loadAllSearchFeatures();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // Bei Änderung der Suchfilter rufen wir onChange auf
    // (außer beim Text, der muss per Button explizit bestätigt werden)
    useEffectNoInitial(
        () => callOnChange(true),
        // Wir nehmen makeSearchFilters explizit nicht in die Liste auf, da sich
        // diese Funktion bei jedem Rendern ändert. Wir wollen aber nur onChange
        // aufrufen, wenn sich die hier angegebenen Parameter ändern
        /* eslint-disable react-hooks/exhaustive-deps */
        [
            serializeSet(searchObjectTypes),
            region,
            numAdults,
            numChildren,
            numBabies,
            serializeSet(searchFeatures),
            isExactSearch,
            sortOrder,
        ],
        /* eslint-enable react-hooks/exhaustive-deps */
    );

    // Außnahme für die Duration: Wir rufen nur dann onChange auf, wenn Start-
    // und Enddatum gesetzt sind.
    useEffectNoInitial(() => {
        if (start && end) {
            callOnChange(true);
        }
    }, [duration]);

    // Außerdem rufen wir nach dem ersten Render einmalig onChange auf
    React.useEffect(
        () => callOnChange(false),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // Wir blenden die Suchleiste nur auf Handys manchmal aus
    const [isMdDown, setIsMdDown] = React.useState(window.matchMedia("(max-width: 767px").matches);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 767px");
        const listener = () => {
            console.log("resize", mediaQuery.matches);
            setIsMdDown(mediaQuery.matches);
            console.log("isMdDown", isMdDown);
        };
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!searchFiltersIsEmpty(currentSearchFilters) && !hidePills && (
                <div className="bg-white border mb-2 p-2 d-md-none">
                    <SearchFilterPills searchFilters={currentSearchFilters} allSearchFeatures={allSearchFeatures} />
                    <button
                        type="button"
                        className="btn btn-sm btn-block btn-outline-secondary"
                        data-toggle="collapse"
                        data-target="#search-sidebar"
                        aria-expanded="false"
                        aria-controls="search-sidebar"
                    >
                        Filter ändern <i className="fa fa-fw fa-caret-down" />
                    </button>
                </div>
            )}

            <div
                id="search-sidebar"
                className={clsx({
                    "sidebar-search": true,
                    collapse: isMdDown,
                    show: React.useMemo(
                        // Wir wollen das nur einmalig beim ersten Render
                        // auswerten. Danach mischen wir uns nicht mehr in
                        // Bootstraps "show"-State ein.
                        () => isMdDown && searchFiltersIsEmpty(currentSearchFilters),
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        [],
                    ),
                })}
            >
                <fieldset className="sidebar-search__fieldset">
                    <h1 className="sidebar-search__legend">Fincasuche</h1>
                    <div className="sidebar-search__container pb-2">
                        <form
                            onSubmit={(event) => {
                                callOnChange(true);
                                event.preventDefault();
                                return false;
                            }}
                        >
                            <div className="input-group my-2">
                                <input
                                    type="text"
                                    placeholder="Fincaname"
                                    className="form-control"
                                    value={text || ""}
                                    onChange={(event) => setText(event.target.value)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="submit">
                                        <i className="fa fa-magnifying-glass" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="form-group">
                            <label htmlFor="date_range">Sortierung</label>
                            <Select
                                noOptionsMessage={() => "Nichts gefunden"}
                                placeholder="Reihenfolge"
                                options={SORT_OPTIONS}
                                value={SORT_OPTIONS.find((o) => o.value === sortOrder)}
                                onChange={(o) => setSortOrder(o?.value ?? SORT_OPTIONS[0].value)}
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="sidebar-search__fieldset">
                    <legend className="sidebar-search__legend">Reisezeitraum</legend>
                    <div className="sidebar-search__container">
                        <ToggleButtonGroup
                            className="mb-3"
                            buttonClass="secondary"
                            value={isExactSearch}
                            setValue={(value) => {
                                setIsExactSearch(value);
                            }}
                            fullWidth
                            isSmall
                            options={[
                                { label: "Flexibel", value: false },
                                { label: "Exakt", value: true },
                            ]}
                        />

                        <div className="form-group">
                            <label htmlFor="date_range">Reisezeitraum</label>
                            <DateRangePicker
                                inputId="date_range"
                                start={start}
                                end={end}
                                setStart={setStart}
                                setEnd={setEnd}
                                onClose={onCloseDateRangePicker}
                                numberOfMonths={isMdDown ? 1 : 2}
                            />
                        </div>

                        {isExactSearch || (
                            <div className="form-group">
                                <label htmlFor="duration">Reisedauer</label>
                                <select
                                    id="duration"
                                    name="duration"
                                    className="form-control"
                                    value={duration || undefined}
                                    onChange={(event) => setDuration(event.target.value as Duration)}
                                >
                                    {DURATIONS.map((option) => (
                                        <option key={option.key} value={option.key}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                </fieldset>
                <fieldset className="sidebar-search__fieldset">
                    <legend className="sidebar-search__legend">Art&nbsp;&amp;&nbsp;Lage</legend>
                    <div className="sidebar-search__container">
                        <div className="form-group">
                            <SetFilter
                                list={SEARCH_OBJECT_TYPES}
                                value={searchObjectTypes}
                                onChange={setSearchObjectTypes}
                                keyGetter={(obj) => obj.key}
                                labelGetter={(obj) => obj.label}
                                countGetter={(obj) => searchResult?.restrictCounts.objectTypes[obj.key]}
                            />
                        </div>

                        <hr />

                        <SetFilter
                            list={SEA_FEATURES}
                            value={searchFeatures}
                            onChange={setSearchFeatures}
                            keyGetter={(obj) => obj.slug}
                            labelGetter={(obj) => obj.name}
                            countGetter={(obj) => searchResult?.restrictCounts.specials[obj.slug]}
                            countColor="success"
                        />

                        <hr />

                        <div className="form-group">
                            <select
                                className="form-control"
                                value={region || ""}
                                onChange={(e) => setRegion((e.target.value || null) as Region | null)}
                            >
                                <option value="">Alle Regionen</option>
                                {REGION_GROUPS.map((group) => (
                                    <optgroup key={group.label} label={group.label}>
                                        {group.regions.map((region) => (
                                            <option key={region.key} value={region.key}>
                                                {region.label}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="sidebar-search__fieldset">
                    <legend className="sidebar-search__legend">Personenzahl</legend>
                    <div className="sidebar-search__container">
                        <div className="form-group">
                            <label htmlFor="adult_count">Erwachsene</label>
                            <select
                                id="adult_count"
                                className="form-control"
                                value={numAdults || 0}
                                onChange={(e) => setNumAdults(parseInt(e.target.value) || null)}
                            >
                                {range(20).map((num) => (
                                    <option key={num} value={num}>
                                        {num === 0 ? "Beliebig" : num}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="child_count">Kinder (2-17 Jahre*)</label>
                            <select
                                id="child_count"
                                className="form-control"
                                value={numChildren || 0}
                                onChange={(e) => setNumChildren(parseInt(e.target.value) || null)}
                            >
                                {range(20).map((num) => (
                                    <option key={num} value={num}>
                                        {num === 0 ? "Beliebig" : num}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="baby_count">Babies (0-24 Monate*)</label>
                            <select
                                id="baby_count"
                                className="form-control"
                                value={numBabies || 0}
                                onChange={(e) => setNumBabies(parseInt(e.target.value) || null)}
                            >
                                {range(20).map((num) => (
                                    <option key={num} value={num}>
                                        {num === 0 ? "Beliebig" : num}
                                    </option>
                                ))}
                            </select>
                            <small id="emailHelp" className="form-text text-muted">
                                * Alter bei Reiseantritt
                            </small>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="sidebar-search__fieldset">
                    <legend className="sidebar-search__legend">Ausstattung</legend>
                    <div className="sidebar-search__container">
                        {allSearchFeatures ? (
                            <SetFilter
                                list={allSearchFeatures.aggregates}
                                value={searchFeatures}
                                onChange={setSearchFeatures}
                                keyGetter={(obj) => obj.slug}
                                labelGetter={(obj) => obj.name}
                                countGetter={(obj) =>
                                    searchResult?.restrictCounts.aggregates[obj.slug] ??
                                    searchResult?.restrictCounts.features[obj.slug]
                                }
                                countColor="success"
                                additionalListHeadline="Weitere Ausstattung"
                                additionalList={allSearchFeatures.features}
                            />
                        ) : (
                            <div className="spinner" />
                        )}
                    </div>
                </fieldset>
            </div>
        </>
    );
};

export default SearchSidebar;
