import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Nullable } from "./Nullable";
import { Salutation } from "./Salutation";

export const ApiResultManagerCreateBookingExistingCustomer = t.type({
    customerId: t.number,
});

export const ApiResultManagerCreateBookingNewCustomer = t.type({
    customerFirstName: t.string,
    customerLastName: t.string,
    customerSalutation: Nullable(Salutation),
    customerEmail: t.string,
    customerTelephoneNumber: t.string,
});

export const ApiResultManagerCreateBookingCustomer = t.union([
    ApiResultManagerCreateBookingExistingCustomer,
    ApiResultManagerCreateBookingNewCustomer,
]);

export const ApiResultManagerCreateBookingData = t.intersection([
    t.type({
        isReservation: t.boolean,
        apartmentId: t.number,
        start: MomentDate,
        end: MomentDate,
        comment: t.string,
    }),
    ApiResultManagerCreateBookingCustomer,
]);

export const ApiResultManagerCreateBooking = t.type({
    bookingId: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCreateBookingExistingCustomer = t.TypeOf<
    typeof ApiResultManagerCreateBookingExistingCustomer
>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCreateBookingNewCustomer = t.TypeOf<typeof ApiResultManagerCreateBookingNewCustomer>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCreateBookingCustomer = t.TypeOf<typeof ApiResultManagerCreateBookingCustomer>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCreateBookingData = t.TypeOf<typeof ApiResultManagerCreateBookingData>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerCreateBooking = t.TypeOf<typeof ApiResultManagerCreateBooking>;
