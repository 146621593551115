import * as t from "io-ts";

import { MomentDate } from "jslib/types";
import { Region } from "./Region";

export const SortOrderType = t.keyof({
    best: null,
    "price-asc": null,
    "price-desc": null,
    rating: null,
    newest: null,
    oldest: null,
});

export const SearchObjectType = t.keyof({
    finca: null,
    fincahotel: null,
    agritourism: null,
    flat: null,
    house: null,
});

export const SearchDurationExact = t.type({
    start: MomentDate,
    end: MomentDate,
});

export const SearchDurationFlexible = t.intersection([
    SearchDurationExact,
    t.type({
        duration: t.tuple([t.number, t.number]),
    }),
]);

export const SearchParticipants = t.type({
    adults: t.number,
    children: t.number,
    babies: t.number,
});

export const SearchFilters = t.partial({
    text: t.string,
    objectType: t.array(SearchObjectType),
    daterange: t.union([SearchDurationExact, SearchDurationFlexible]),
    limit: t.number,
    offset: t.number,
    region: Region,
    participants: SearchParticipants,
    features: t.array(t.string),
    sid: t.string,
    orderBy: SortOrderType,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SortOrderType = t.TypeOf<typeof SortOrderType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchObjectType = t.TypeOf<typeof SearchObjectType>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchDurationExact = t.TypeOf<typeof SearchDurationExact>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchDurationFlexible = t.TypeOf<typeof SearchDurationFlexible>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchParticipants = t.TypeOf<typeof SearchParticipants>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchFilters = t.TypeOf<typeof SearchFilters>;
