import * as t from "io-ts";

import { BookingM } from "./Booking";
import { CustomerM } from "./Customer";
import { Finca } from "./Finca";
import { Apartment } from "./Apartment";

export const ApiResultManagerBooking = t.type({
    booking: BookingM,
    customer: CustomerM,
    finca: Finca,
    apartment: Apartment,
    customerPastBookingsOnFinca: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerBooking = t.TypeOf<typeof ApiResultManagerBooking>;
