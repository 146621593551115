import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import de from "date-fns/locale/de";
import Dinero from "dinero.js";
import moment from "moment";
import "moment-duration-format";
import "moment/locale/de";
import React from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { WishlistFincaData } from "types";
import Fincasearch from "./Fincasearch";
import i18nInit from "./i18n/index";
import Wishlist from "./Wishlist";

// Sentry
if ((window as any)._FINCASEARCH_SENTRY_DSN.slice(0, 4) === "http") {
    const version = process.env.REACT_APP_VERSION || "unknown";
    Sentry.init({
        dsn: (window as any)._FINCASEARCH_SENTRY_DSN,
        environment: (window as any)._FINCASEARCH_SENTRY_ENVIRONMENT,
        release: `fincasearch@${version}`,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.01,
    });
}

// i18n
i18nInit();

// react-datepicker
registerLocale("de", de);
setDefaultLocale("de");

// Moment
moment.locale("de");

// Dinero
Dinero.defaultCurrency = "EUR";
Dinero.globalLocale = "de-DE";
Dinero.defaultPrecision = 2;
Dinero.globalFormat = "$0";

// Ist localStorage verfügbar?
function testIfLocalStorageIsAvailable() {
    const test = "test";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export const isLocalStorageAvailable = testIfLocalStorageIsAvailable();

// Fincasuche
const fincasearchDomElement = document.getElementById("fincasearch");
const serverContent = document.getElementById("fincasearch-content");
if (serverContent && serverContent.parentElement) {
    serverContent.parentElement.removeChild(serverContent);
    while (serverContent.attributes.length > 0) {
        serverContent.removeAttributeNode(serverContent.attributes[0]);
    }
}

if (fincasearchDomElement != null) {
    Modal.setAppElement("#fincasearch");
    ReactDOM.render(
        <React.StrictMode>
            <Fincasearch serverContent={serverContent} />
        </React.StrictMode>,
        fincasearchDomElement,
    );
}

// Wunschliste
const wishlistDesktopElement = document.getElementById("wishlist-desktop");
const wishlistMobileElement = document.getElementById("wishlist-mobile");

const renderWishList = (element: HTMLElement | null) => {
    if (element !== null) {
        const fincaData = document.getElementById("finca_extractable_data");
        const finca: WishlistFincaData | undefined = fincaData
            ? {
                  name: fincaData.getAttribute("data-finca-name")!,
                  url: fincaData.getAttribute("data-finca-url")!,
                  imageUrl: fincaData.getAttribute("data-finca-image-url")!,
                  price: fincaData.getAttribute("data-finca-price")!,
              }
            : undefined;
        const button = element.children[0];
        const buttonClasses = button.className;
        ReactDOM.render(
            <React.StrictMode>
                <Wishlist possibleFinca={finca} buttonClasses={buttonClasses} />
            </React.StrictMode>,
            element,
        );
    }
};

renderWishList(wishlistDesktopElement);
renderWishList(wishlistMobileElement);
