import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Dinero } from "./Dinero";

export const AdditionalBedPrice = t.type({
    start: MomentDate,
    end: MomentDate,
    value: Dinero,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AdditionalBedPrice = t.TypeOf<typeof AdditionalBedPrice>;
