import * as t from "io-ts";
import { MomentDuration, MomentDateTime } from "./Moment";
import { Nullable } from "./Nullable";

export const SuggestedArrivalDeparture = t.type({
    minArrival: MomentDateTime,
    maxDeparture: MomentDateTime,
    travelTime: MomentDuration,
    arrivalBaggage: MomentDuration,
    departureBaggage: MomentDuration,
    landing: Nullable(MomentDateTime),
    takeoff: Nullable(MomentDateTime),
    estimatedArrival: Nullable(MomentDateTime),
    estimatedDeparture: Nullable(MomentDateTime),
    suggestedArrival: Nullable(MomentDateTime),
    suggestedDeparture: Nullable(MomentDateTime),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuggestedArrivalDeparture = t.TypeOf<typeof SuggestedArrivalDeparture>;
