import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Nullable } from "./Nullable";

export const BookingRuleSetParameters = t.type({
    end: MomentDate,
    start: MomentDate,
    minimumStay: t.number,
    minimumStayRequireConfirmation: Nullable(t.number),
    maximumGap: Nullable(t.number),
    allowedWeekdays: t.array(t.number),
    easterRule: t.boolean,
    minimumStayShortGaps: Nullable(t.number),
    minimumStayMediumGaps: Nullable(t.number),
    minimumGap: t.number,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BookingRuleSetParameters = t.TypeOf<typeof BookingRuleSetParameters>;
