import axios from "axios";
import { isRight, Either } from "fp-ts/Either";
import { StructuralErrorReporter } from "@pavelkucera/io-ts-structural-error-reporter";
import reporter from "io-ts-reporters";
import * as Sentry from "@sentry/react";
import * as t from "io-ts";

import { ApiResponseSuccess, ApiResponseFailed, ApiErrorType } from "jslib/types";

class ParserErrorException extends Error {
    validation: Either<t.Errors, unknown>;
    constructor(validation: Either<t.Errors, unknown>) {
        // Benutze io-ts-reporters für eine Fehlermeldung als String
        super(reporter.report(validation)[0] || "(unknown)");
        this.validation = validation;
        this.name = new.target.prototype.constructor.name;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

async function makeApiResponse<T>(
    fn: () => Promise<Either<t.Errors, T>>,
): Promise<ApiResponseSuccess<T> | ApiResponseFailed> {
    try {
        const data = await fn();

        if (isRight(data)) {
            return {
                apiSuccess: true,
                data: data.right,
            };
        } else {
            throw new ParserErrorException(data);
        }
    } catch (error) {
        let errorType: ApiErrorType;
        let errorMessage: string;
        let errorExtra: any;

        if (error instanceof ParserErrorException && error?.validation) {
            // Unser eigener ParserError aus dem io-ts-structural-error-reporter
            // Diesen reporten wir noch explizit an Sentry (in prod)
            errorType = "ParserError";
            errorMessage = "Die Daten entsprechen nicht dem erwarteten Format.";

            // Nutze io-ts-structural-error-reporter für Fehlermeldung als
            // Objekt
            const errors = StructuralErrorReporter.report(error.validation);
            if (isRight(errors)) {
                errorExtra = errors.right;
            }

            if (process.env.NODE_ENV === "production") {
                Sentry.captureException(error, { extra: errorExtra });
            }
        } else if (axios.isAxiosError(error) && error?.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 404) {
                errorType = "NotFoundError";
                errorMessage = "Die gewünschte Seite wurde nicht gefunden.";
            } else if (error.response.status === 403) {
                errorType = "ForbiddenError";
                errorMessage = "Zugriff nicht erlaubt. Sind Sie eingeloggt?";
            } else {
                errorType = "ServerError";
                errorMessage = `Der Server hat mit Status ${error.response.status} geantwortet.`;
            }
        } else if (axios.isAxiosError(error) && error?.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            errorType = "NetworkError";
            errorMessage = "Der Server ist nicht erreichbar.";
        } else if (axios.isCancel(error)) {
            errorType = "Cancelled";
            errorMessage = "Die Anfrage wurde vom Benutzer abgebrochen.";
        } else {
            // Something happened in setting up the request that triggered an Error
            errorType = "OtherError";
            errorMessage = "Ein unbekannter Fehler ist aufgetreten.";
        }

        console.error(errorType, error, errorExtra);

        return {
            apiSuccess: false,
            apiErrorMessage: errorMessage,
            apiErrorType: errorType,
            apiException: error,
        };
    }
}

export default makeApiResponse;
