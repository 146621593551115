import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { Breakpoint } from "./Breakpoint";

export const ImageTuple = t.tuple([t.tuple([t.string, t.number, t.number]), t.tuple([t.string, t.number, t.number])]);

export const Image = t.type({
    height: t.number,
    width: t.number,
    text: Nullable(t.string),
    url: t.string,
});

export const ImageTupleBreakpoints = t.record(Breakpoint, ImageTuple);
export const ImageTupleBreakpointsFull = t.type({
    full: ImageTuple,
    breakpoints: t.record(Breakpoint, ImageTuple),
    caption: Nullable(t.string),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Image = t.TypeOf<typeof Image>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ImageTuple = t.TypeOf<typeof ImageTuple>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ImageTupleBreakpoints = t.TypeOf<typeof ImageTupleBreakpoints>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ImageTupleBreakpointsFull = t.TypeOf<typeof ImageTupleBreakpointsFull>;
